import { get } from "../../api/laravelApi";
import { caseOptionsEndpoint } from "../../constants";
import { getSearchParams } from "../loaderHelper";

export const CaseOptionsLoader = async ({ request }) => {
  const url = new URL(request.url);
  const searchParams = new URLSearchParams(url.search);

  const allParams = getSearchParams(searchParams);

  const getAllFiltersJson = await get(caseOptionsEndpoint);
  const allFiltersJson = getAllFiltersJson;
  const formatParams = Object.values(allFiltersJson).map((value) => {
    const filterValue = value as {
      key: string;
      searchParamName: string;
      type: string;
    };
    return {
      key: filterValue.key,
      label: filterValue.searchParamName,
      type: filterValue.type,
    };
  });

  const data = await get(caseOptionsEndpoint, allParams, formatParams);

  return data;
};
