import { useEffect } from "react";
import useRoomsHistoryViewModel from "../../viewmodel/RoomsHistory/useRoomsHistoryViewModel";
import useSharedFunctions from "../sharedFunctions/useSharedFunctions";
import { formatTimeWithDate } from "../../transformers/utils";
import MissingData from "../../Components/MissingData/MissingData";
import { convertSecondsToHoursMinutesSeconds } from "../../utils";

const useRoomsHistoryViewController = () => {
  const { transformedRoomsHistoryData: roomsHistoryData } =
    useRoomsHistoryViewModel();

  const {
    handleDateChange,
    startDate,
    endDate,
    onFilterChange,
    filters,
    applyFilters,
    setSelected,
    setPeerFilters,
    toggleButton,
    disableExcelDoc,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  } = useSharedFunctions(roomsHistoryData);

  useEffect(() => {
    roomsHistoryData.filters.forEach((filter) => {
      setPeerFilters(filter.name, filter.peerFilters!);
      setSelected(filter.name, filter.selection);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatEntryAndExit(entryOrExit: string) {
    return (dataIndex: number) => {
      return formatTimeWithDate(
        roomsHistoryData.visits[dataIndex][entryOrExit],
        roomsHistoryData.visits[dataIndex].timezone,
        "Time"
      );
    };
  }

  const tableColumns = [
    {
      name: "entry",
      label: "Entry",
      options: { customBodyRenderLite: formatEntryAndExit("entry") },
    },
    {
      name: "exit",
      label: "Exit",
      options: { customBodyRenderLite: formatEntryAndExit("exit") },
    },
    { name: "duration", label: "Duration" },
    { name: "staffMember", label: "Staff Member" },
    { name: "groupName", label: "Group Name" },
    { name: "overlapping", label: "Overlapping Visit" },
    { name: "overlappingVisitDuration", label: "Overlapping Visit Duration" },
  ];

  const tableData = roomsHistoryData?.visits?.map((visit) => {
    const entry = visit.entry;
    const exit = visit.exit;
    const staffMember = visit?.staff?.name;
    const groupName = visit?.staff?.group;
    const duration = convertSecondsToHoursMinutesSeconds(visit.durationSeconds);
    const overlapping = visit?.overlapping ? "Yes" : "No";
    const overlappingVisitDuration = visit?.overlappingVisitDurationSeconds
      ? convertSecondsToHoursMinutesSeconds(
          visit.overlappingVisitDurationSeconds
        )
      : "N/A";

    return {
      entry,
      exit,
      staffMember,
      groupName,
      duration,
      overlapping,
      overlappingVisitDuration,
    };
  });

  const tableOptions = {
    download: false,
    sortOrder: {
      name: "entry",
      direction: "desc",
    },
    textLabels: {
      body: {
        noMatch: (
          <MissingData
            message={"No Data Available."}
            action={"Please select a room or try a different selection"}
          />
        ),
      },
    },
  };

  return {
    roomsHistoryData,
    startDate,
    endDate,
    handleDateChange,
    filters,
    onFilterChange,
    applyFilters,
    toggleButton,
    disableExcelDoc,
    tableColumns,
    tableData,
    tableOptions,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  };
};

export default useRoomsHistoryViewController;
