import { useLoaderData } from "react-router-dom";
import { RoomsHistoryDTO } from "../types";

const useRoomsHistoryModel = () => {
  const roomsHistoryData = useLoaderData() as RoomsHistoryDTO;

  return {
    roomsHistoryData,
  };
};

export default useRoomsHistoryModel;
