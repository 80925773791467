import { SetStateAction, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { useUserInfo } from "../../contexts/UserInfo/userInfo";

import Logo from "../UI/Logo/Logo";

import LogoutButton from "../LogoutButton/LogoutButton";
import LoginButton from "../LoginButton/LoginButton";
import HubApp from "@untitled-ui/icons-react/build/cjs/LayoutAlt02";
import { useModuleTheme } from "../../contexts/ModuleTheme/moduleThemes";

function NavigationBottom() {
  const { userInfo } = useUserInfo();
  const { theme } = useModuleTheme();

  const [activeButton, setActiveButton] = useState(theme);

  useEffect(() => {
    const themeButtonMap = {
      "hand-hygiene": "hand-hygiene",
      "asset-tracking": "asset-tracking",
      "nursing-insights": "nursing-insights",
      "contact-tracing": "contact-tracing",
    };

    setActiveButton(themeButtonMap[theme]);
  }, [theme]);

  const handleButtonClick = (button: SetStateAction<string>) => {
    if (theme !== null && theme !== undefined) {
      setActiveButton(button);
    }
  };

  return (
    <>
      <div className="bottom-content">
        <li className="hidden-desktop">
          <NavLink to={`${process.env.REACT_APP_AUTH_DOMAIN}`}>
            <HubApp />
            Hub App
          </NavLink>
        </li>
        <li className="hidden-desktop">
          {JSON.stringify(userInfo) === "{}" ? (
            <LoginButton />
          ) : (
            <LogoutButton />
          )}
        </li>
        <li className="hidden-desktop">
          <div className="dividing-line"></div>
        </li>
        <div className="bottom-content-header">
          <h4>Your Modules</h4>
        </div>
        <div className="display-flex flex-wrap padding-bottom-mobile">
          <li className="no-padding">
            {(userInfo?.permissions?.includes("HH.dashboard.executive") ||
              userInfo?.permissions?.includes("HH.report.blueprint.overTime") ||
              userInfo?.permissions?.includes("HH.report.blueprint.realTime") ||
              userInfo?.permissions?.includes("HH.report.group") ||
              userInfo?.permissions?.includes("HH.report.me") ||
              userInfo?.permissions?.includes("HH.report.staff") ||
              userInfo?.permissions?.includes("HH.report.unit")) && (
              <Logo
                testid="hh-logo"
                onClick={() => handleButtonClick("hand-hygiene")}
                url={"/HH/"}
                iconUrl="/hh-logo.svg"
                className={`no-padding module-select ${
                  activeButton === "hand-hygiene" && "highlight"
                }`}
              />
            )}
          </li>
          {userInfo?.permissions?.includes("assetTracking.view") && (
            <li className="no-padding">
              <Logo
                testid="at-logo"
                onClick={() => handleButtonClick("asset-tracking")}
                url={"/AT/assets"}
                iconUrl="/at_app.svg"
                className={`no-padding module-select ${
                  activeButton === "asset-tracking" && "highlight"
                }`}
              />
            </li>
          )}
          {(userInfo?.permissions?.includes("NI.report.places") ||
            userInfo?.permissions?.includes("NI.report.roomsHistory")) && (
            <li className="no-padding">
              <Logo
                testid="ni-logo"
                onClick={() => handleButtonClick("nursing-insights")}
                url={"/NI/rounding"}
                iconUrl="/ni-logo.svg"
                className={`no-padding module-select ${
                  activeButton === "nursing-insights" && "highlight"
                }`}
              />
            </li>
          )}
          {(userInfo?.permissions?.includes("CT.case.view") ||
            userInfo?.permissions?.includes("CT.case.create")) && (
            <li className="no-padding">
              <Logo
                testid="ct-logo"
                onClick={() => handleButtonClick("contact-tracing")}
                url={"/CT/new-case"}
                iconUrl="/ct-logo.svg"
                className={`no-padding module-select ${
                  activeButton === "contact-tracing" && "highlight"
                }`}
              />
            </li>
          )}
        </div>
      </div>
    </>
  );
}

export default NavigationBottom;
