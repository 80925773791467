import LinearProgress from "../UI/LinearProgress/LinearProgress";
import Table from "../../Components/Table/Table";
import MissingData from "../MissingData/MissingData";
import { AverageCompliance } from "../../types/types";
import { SetStateAction, useEffect, useState } from "react";

type StaffTableProps = {
  data: AverageCompliance[];
  userInfo?: Object;
  updateRowDataId: (rowData: number) => void;
  rowClickAction: () => void;
};

function StaffTable(props: StaffTableProps) {
  const [rowDataValue, setRowData] = useState<number | null>(null);

  useEffect(() => {
    if (rowDataValue) {
      props.updateRowDataId(rowDataValue);
      props.rowClickAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowDataValue]);

  const columns = [
    {
      name: "provider_id",
      label: "Provider ID",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Staff Name",
    },
    {
      name: "lastDetected",
      label: "Last Detected",
    },
    {
      name: "opportunities",
      label: "Opportunities",
    },
    {
      name: "percentage",
      label: "Performance",
      options: {
        customBodyRender: (value: number) => {
          return (
            <LinearProgress
              percentage={value}
              data-testid="linear-progress"
            ></LinearProgress>
          );
        },
      },
    },
  ];

  const options = {
    onRowClick: (rowData: SetStateAction<number | null>[]) => {
      setRowData(rowData[0]);
      props.updateRowDataId(rowDataValue as number);
      props.rowClickAction();
    },
    rowHover: true,
    sortOrder: {
      name: "percentage",
      direction: "desc",
    },
    textLabels: {
      body: {
        noMatch: (
          <MissingData
            message={"No Data Available."}
            action={"Please make a different selection."}
          />
        ),
      },
    },
  };

  return (
    <div className="staff-table">
      <Table
        title={"Hand Hygiene Performance by Staff"}
        data={props.data}
        columns={columns}
        optionOverrides={options}
      ></Table>
    </div>
  );
}

export default StaffTable;
