import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
import "./Scss/style.scss";

import { createMockServer } from "./mock/server";

import Loading from "./Components/UI/Loading/Loading";

import { UserInfoProvider } from "./contexts/UserInfo/userInfo";
import routesConfig from "./routesConfig";

if (process.env.REACT_APP_WITH_MOCK) {
  createMockServer({ environment: "development" });
}

Sentry.init({
  dsn: "https://e2d1cf1143fb4aea8ef50926c2e1c6a2@o94107.ingest.sentry.io/4504329503375360",
  integrations: [Sentry.browserTracingIntegration()],
  environment: process.env.REACT_APP_DEPLOYED_ENVIRONMENT,
  // release: '1.0.0',

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We should adjust this value in production
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    // filter out token expiration responses from going to sentry/slack
    if (hint.originalException instanceof Response) {
      if (hint.originalException?.status === 401) {
        return null;
      }
    }
    return event;
  },
});

function App() {
  const router = createBrowserRouter(routesConfig);

  return (
    <>
      <UserInfoProvider>
        <div className={"app-container"}>
          <RouterProvider
            router={router}
            fallbackElement={
              <div className="fallback-loader">
                <Loading />
              </div>
            }
          />
        </div>
      </UserInfoProvider>
    </>
  );
}

export default Sentry.withProfiler(App);
