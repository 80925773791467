import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import Alert from "@untitled-ui/icons-react/build/cjs/AlertTriangle";
import Refresh from "@untitled-ui/icons-react/build/cjs/RefreshCcw02";

function MaintenancePage() {
  const navigate = useNavigate();
  const title = "We are currently undergoing maintenance.";
  const message = "Please try again later.";
  const button = "Try Again";
  const icon = <Refresh />;

  return (
    <>
      <TopNavigation />
      <main className="overview-layout position-relative">
        <div className="center-content full-height">
          <div className="content-container">
            <h1 className="margin-bottom">{title}</h1>
            <div className="warning-icon">
              <Alert />
            </div>
            <p className="enlarged-text margin-bottom-2x">{message}</p>
            <button onClick={() => navigate(-1)}>
              {icon}
              {button}
            </button>
          </div>
        </div>
      </main>
      <footer className="overview-layout-footer">
        <Footer />
      </footer>
    </>
  );
}

export default MaintenancePage;
