export function getSearchParams(searchParams: URLSearchParams) {
  const allParams = {};
  for (const [key, value] of searchParams.entries()) {
    if (key === "DateRangeIds") continue;
    if (key in allParams) {
      if (Array.isArray(allParams[key])) {
        allParams[key].push(value);
      } else {
        allParams[key] = [allParams[key], value];
      }
    } else {
      allParams[key] = value;
    }
  }
  return allParams;
}

export function getSearchParamsDefer(searchParams: URLSearchParams) {
  const allParams = {};
  for (const [key, value] of searchParams.entries()) {
    if (key === "DateRangeIds") continue;
    if (!allParams[key]) {
      allParams[key] =
        key === "startDate" || key === "endDate"
          ? value
          : value === "null"
            ? []
            : [value];
    } else {
      allParams[key] = Array.isArray(allParams[key])
        ? [...allParams[key], value]
        : [allParams[key], value];
    }
  }
  return allParams;
}
