import { useEffect, useState } from "react";
import useStaffReportViewModel from "../../viewmodel/Staff/useStaffReportViewModel";

import useSharedFunctions from "../sharedFunctions/useSharedFunctions";
import { formatPercentage } from "../../utils";

const useStaffReportViewController = () => {
  const { transformedStaffReportData } = useStaffReportViewModel();
  const {
    handleDateChange,
    startDate,
    endDate,
    onFilterChange,
    filters,
    applyFilters,
    setSelected,
    setPeerFilters,
    toggleButton,
    disableExcelDoc,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  } = useSharedFunctions(transformedStaffReportData);

  const [rowValue, setRowValue] = useState<number | null>(null);

  const goToIndividualStaffPage = (value: number) => {
    setRowValue(value);

    const searchParams = new URLSearchParams();
    searchParams.set("ProvidersIds", value.toString());

    const url = `/HH/staff/${value}?${searchParams.toString()}`;

    window.location.href = url;
  };

  const pdfTables = [
    {
      head: [["Total Performance", "Percentage Changed"]],
      body: [
        [
          transformedStaffReportData?.tiles?.performance
            ? formatPercentage(transformedStaffReportData?.tiles?.performance) +
              "%"
            : "No Data",
          transformedStaffReportData?.tiles?.percentChangedFormatted ||
            "No Data",
        ],
      ],
    },
    {
      head: [["Total", "Performant"]],
      body: [
        [
          transformedStaffReportData?.tiles?.total || "No Data",
          transformedStaffReportData?.tiles?.performant || "No Data",
        ],
      ],
    },
    {
      head: [["Staff Name", "Last Detected", "Opportunities", "Performance"]],
      body: transformedStaffReportData?.averageCompliance
        ? transformedStaffReportData?.averageCompliance?.map((item) => {
            return [
              item.name || "No Data",
              item.lastDetected || "No Data",
              item.opportunities || "No Data",
              item.percentage || "No Data",
            ];
          })
        : [["No Data", "No Data", "No Data", "No Data"]],
    },
  ];

  useEffect(() => {
    transformedStaffReportData?.filters?.forEach((filter) => {
      setPeerFilters(filter.name, filter.peerFilters!);
      setSelected(filter.name, filter.selection);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this is a hack - we need users filter on create case, but not on Staff page
  const filtersWithoutUsers = filters.filter(
    (filter) => filter.name !== "Users"
  );

  return {
    pdfTables,
    transformedStaffReportData,
    startDate,
    endDate,
    handleDateChange,
    filters: filtersWithoutUsers,
    onFilterChange,
    applyFilters,
    toggleButton,
    disableExcelDoc,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
    setRowValue,
    rowValue,
    goToIndividualStaffPage,
  };
};

export default useStaffReportViewController;
