import { get } from "../../api/laravelApi";
import { roomsHistoryEndpoint } from "../../constants";
import { getSearchParams } from "../loaderHelper";

export const RoomsHistoryLoader = async ({ request }) => {
  const url = new URL(request.url);
  const searchParams = new URLSearchParams(url.search);

  const allParams = getSearchParams(searchParams);

  const getAllFiltersJson = await get(roomsHistoryEndpoint);
  const allFiltersJson = getAllFiltersJson.params;

  const transformedFiltersJson = Object.values(allFiltersJson).map((value) => {
    const filterValue = value as {
      key: string;
      searchParamName: string;
      type: string;
    };
    return {
      key: filterValue.key,
      label: filterValue.searchParamName,
      type: filterValue.type,
    };
  });

  const data = await get(
    roomsHistoryEndpoint,
    allParams,
    transformedFiltersJson
  );

  return data;
};
