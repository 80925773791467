import { Filter, GroupedOptions, Option } from "../../../../src/types/types";
import {
  DependencyValues,
  RawOptionsObject,
  RawOptionsWithHeaders,
} from "../types/types";

export function parseOptions(
  options: RawOptionsObject,
  group?: string
): Option[] {
  const parsedOptions: Option[] = [];
  if (!group) {
    Object.keys(options).forEach((key) => {
      parsedOptions.push({
        value: key,
        label: options[key],
      });
    });
  } else {
    Object.keys(options).forEach((key) => {
      parsedOptions.push({
        value: key,
        label: options[key],
        group: {
          label: group,
        },
      });
    });
  }
  // sort options alphabetically by label
  parsedOptions.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return parsedOptions;
}

export function parseOptionsHeaders(optionsWithHeaders: {
  [key: string]: RawOptionsObject;
}): GroupedOptions[] {
  // split key on the second #CSSEV# because what comes after that is the header to use
  const parseKey = (key: string): string => {
    // hack to get this to work on initial page load also.
    // for some reason the options in the initial response do not have the #CHSSV# prefix
    if (!key.startsWith("#CHSSV#")) return key;
    const splitKey = key.split("#CSSEV#");
    return splitKey[1];
  };

  return Object.keys(optionsWithHeaders).map((key) => {
    const parsedKey = parseKey(key);
    const parsedOptions = parseOptions(optionsWithHeaders[key], parsedKey);

    const groupedOptions: GroupedOptions = {
      label: parsedKey || "No Group",
      options: parsedOptions,
    };

    return groupedOptions;
  });
}

export function getDependencyValuesForPeerFilters(
  peerFilters: string[] = [],
  filters: Filter[]
): DependencyValues {
  const dependencyValues: DependencyValues = {};

  peerFilters.forEach((pf) => {
    const filter = filters.find((f) => f.filterKey === pf);

    if (filter && filter.selection.length > 0) {
      if (filter.selection[0].value === "null") {
        dependencyValues[pf] = null;
      } else {
        dependencyValues[pf] = filter.selection.map((s) => parseInt(s.value!));
      }
    } else {
      dependencyValues[pf] = null;
    }
  });
  return dependencyValues;
}

export function isRawOptionsWithHeaders(
  options: RawOptionsObject | RawOptionsWithHeaders
): options is RawOptionsWithHeaders {
  const [firstEntry] = Object.entries(options);
  if (!firstEntry) return false;

  // If the first entry's value is an object, then it's a RawOptionsWithHeaders
  return firstEntry[1] instanceof Object;
}

export function isGroupedOptions(
  options: Option[] | GroupedOptions[]
): options is GroupedOptions[] {
  if (options.length === 0) return false;
  return options[0].hasOwnProperty("options");
}

export function isGroupedSelection(selection: Option[]) {
  if (selection.length === 0) return false;
  return selection[0].hasOwnProperty("group");
}

export function getNewGroupedOption(
  nonGroupedSelectedOption: Option,
  updatedOptions: GroupedOptions[]
): Option {
  let groupedSelectedOption = nonGroupedSelectedOption;

  for (let index = 0; index < updatedOptions.length; index++) {
    const groupedOption = updatedOptions[index];
    const newGroupedOption = groupedOption.options.find((option) => {
      return option.value === nonGroupedSelectedOption.value;
    });

    if (newGroupedOption) {
      groupedSelectedOption = newGroupedOption;
      break;
    }
  }

  return groupedSelectedOption;
}

export function getNewNonGroupedOption(
  groupedSelectedOption: Option,
  updatedOptions: Option[]
) {
  let nonGroupedSelectedOption = groupedSelectedOption;

  for (let index = 0; index < updatedOptions.length; index++) {
    const option = updatedOptions[index];
    if (option.value === groupedSelectedOption.value) {
      nonGroupedSelectedOption = option;
      break;
    }
  }

  return nonGroupedSelectedOption;
}

export function getAllNewSelectedOptions(
  currentSelection: Option[],
  updatedOptions: GroupedOptions[] | Option[]
) {
  let updatedSelection = currentSelection;

  if (isGroupedOptions(updatedOptions)) {
    updatedSelection = currentSelection.map((selected) => {
      const newGroupedOption = getNewGroupedOption(selected, updatedOptions);

      return newGroupedOption;
    });
  } else {
    updatedSelection = currentSelection.map((selected) => {
      const newNonGroupedOption = getNewNonGroupedOption(
        selected,
        updatedOptions
      );
      return newNonGroupedOption;
    });
  }

  return updatedSelection;
}
