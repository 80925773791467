import {
  cloneDeep as lodashCloneDeep,
  difference as lodashDifference,
  uniqueId as lodashUniqueId,
} from "lodash";
import $ from "jquery";

const CoreScripts = {
  /* Abort an in progress ajax request */
  abortAjaxRequest: function (requestXhr) {
    if (requestXhr === undefined) {
      return;
    }

    try {
      requestXhr.onreadystatechange = null;
      requestXhr.abort();
    } catch (e) {
      console.log("Ajax request aborted");
    }
  },

  /**
   * Create a bootstrap alert modal and display it
   */
  bootstrapAlert: function (
    title,
    message,
    actionButton1,
    actionButton2,
    actionButton3,
    submitButton,
    cancelButtonText
  ) {
    //Create Edit Modal
    var $existingAlerts = $(".chshBootstrapAlertModal");
    if ($existingAlerts.length > 0) {
      $existingAlerts.modal("hide");
    }
    var $modalDialogWarning = $("<div></div>")
      .attr("class", "modal fade retain overlay chshBootstrapAlertModal")
      .attr("role", "dialog");
    $(":root body").append($modalDialogWarning);
    var $modalContent = $("<div></div>").attr(
      "class",
      "modal-content wordwrap-normal"
    );
    $modalDialogWarning.append(
      $("<div></div>")
        .attr("class", "modal-dialog modal-md")
        .append($modalContent)
    );
    var $modalHeader = $("<div></div>").attr("class", "modal-header");
    $modalContent.append($modalHeader);
    var $closeButton = $("<button></button>")
      .attr("class", "close")
      .attr("data-dismiss", "modal")
      .html("&times;");
    $modalHeader.append($closeButton);
    var $modalBody = $("<div></div>").attr("class", "modal-body");
    $modalContent.append($modalBody);

    var $modalBodyRow = $("<div></div>").attr("class", "row");
    $modalBody.append($modalBodyRow);
    var $modalBodyRowCol = $("<div></div>").attr("class", "col-xs-12");
    $modalBodyRow.append($modalBodyRowCol);
    if (message) {
      $modalBodyRowCol.append(
        $('<span class="alertContent"></span>').html(message)
      );
    }
    var $modalFooter = $("<div></div>").attr("class", "modal-footer clearfix");
    $modalContent.append($modalFooter);
    var $leftButtons = $("<div></div>").attr(
      "class",
      "leftButtons pull-left no-padding-left text-left"
    );
    $modalFooter.append($leftButtons);
    var $rightButtons = $("<div></div>").attr(
      "class",
      "rightButtons pull-right no-padding-right text-right"
    );
    $modalFooter.append($rightButtons);

    $modalHeader.append(
      $("<h4></h4>").attr("class", "modal-title").html(title)
    );

    $rightButtons.append(
      $("<button></button>")
        .attr("type", "button")
        .attr("class", "btn btn-default cancelButton")
        .attr("data-dismiss", "modal")
        .html(cancelButtonText ?? "Dismiss")
    );
    if (submitButton) {
      $rightButtons.append(submitButton);
    }

    if (actionButton1) {
      $leftButtons.append(actionButton1);
    }
    if (actionButton2) {
      $leftButtons.append(actionButton2);
    }
    if (actionButton3) {
      $leftButtons.append(actionButton3);
    }

    //Destroy modal when hidden
    $modalDialogWarning.on("hidden.bs.modal", function () {
      $(this).remove();
    });
    //Handle Modal Show
    $modalDialogWarning.modal("show");
    window.CoreScripts.setupChosenSelects();

    return $modalDialogWarning;
  },

  /* Alias through to lodash cloneDeep function */
  cloneDeep: function (...args) {
    return lodashCloneDeep(...args);
  },

  /* Copy text to clipboard */
  copyText: function (text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
    } else {
      navigator.clipboard.writeText(text).then(
        function () {
          //Completed successfully
        },
        function () {
          // console.log("Clipboard error");
        }
      );
    }

    function fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
      } catch (err) {
        //Do nothing - error occurred
      }

      document.body.removeChild(textArea);
    }
  },

  decodeHtmlEntities(encodedString) {
    var div = document.createElement("div");
    div.innerHTML = encodedString;

    return div.textContent;
  },

  /* Deselect any currently selected text */
  deselectSelectedText: function () {
    document.getSelection().removeAllRanges();
  },

  /* Alias through to lodash difference function */
  difference: function (...args) {
    return lodashDifference(...args);
  },

  elementOpenTarget: function ($linkObject, e) {
    var linkHref = $linkObject.attr("href");
    if (linkHref === "#") {
      //do nothing
    } else if ($linkObject.attr("data-toggle") === "modal") {
      CoreScripts.linkOpenModal(linkHref, $linkObject.attr("data-target"));
    } else {
      if (e !== undefined && e.which === 2) {
        window.open(linkHref, "_blank");
      } else {
        window.location.href = linkHref;
      }
    }
  },

  /**
   * Return float val of value if convertable or return fallback if not
   * @param  {potential number to parse} value
   * @param  {Number} fallback to output in case error (if input is NaN) - defaults to null
   * @return number
   */
  filterFloat: function (value, fallback = null) {
    const output = parseFloat(value);
    if (isNaN(output)) {
      return fallback;
    }

    return output;
  },

  /**
   * Return int val of value if convertable or return fallback if not
   * @param  {potential number to parse} value
   * @param  {Number} fallback to output in case error (if input is NaN) - defaults to null
   * @return number
   */
  filterInt: function (value, fallback = null, radix = 10) {
    const output = parseInt(value, radix);
    if (isNaN(output)) {
      return fallback;
    }

    return output;
  },

  /* Gets parameter value from current URL */
  getCurrentUrlParameter: function (sParam) {
    var sPageURL = CoreScripts.decodeHtmlEntities(
        window.location.search.substring(1)
      ),
      sParameterName = null,
      sURLVariables = sPageURL.split("&");

    for (var i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
  },

  /* Get the proper filtering value to use for a given selector */
  getFilteringValueForSelector($selector) {
    var value = encodeURIComponent(null);

    if ($selector == null || $selector.length === 0) {
      return value;
    }

    $selector.each(function () {
      value = $(this).apiCompatibleVal();

      return false;
    });

    return value;
  },

  getUniqueId(prefix = "") {
    return lodashUniqueId(prefix);
  },

  openModal: function (modalSize, title, bodyContent, includeCloseBtn = false) {
    var modal = document.createElement("div");
    modal.className = "modal fade retain";
    modal.tabIndex = 400;
    modal.setAttribute("role", "dialog");

    var dialog = document.createElement("div");
    dialog.className = "modal-dialog";
    if (modalSize === "x-large") {
      dialog.className += " modal-xl";
    } else if (modalSize === "large") {
      dialog.className += " modal-lg";
    } else if (modalSize === "medium") {
      // do nothing
    } else if (modalSize === "small") {
      dialog.className += " modal-sm";
    } else {
      console.log("invalid modal size");
    }

    dialog.setAttribute("role", "document");

    var content = document.createElement("div");
    content.className = "modal-content";

    modal.appendChild(dialog);
    dialog.appendChild(content);

    var header = document.createElement("div");
    header.className = "modal-header";

    content.appendChild(header);

    var xOutBtn = document.createElement("button");
    xOutBtn.type = "button";
    xOutBtn.className = "close";
    xOutBtn.setAttribute("data-dismiss", "modal");
    xOutBtn.setAttribute("aria-label", "Close");
    xOutBtn.innerHTML = '<span aria-hidden="true">&times;</span>';

    var titleElement = document.createElement("h4");
    titleElement.className = "modal-title text-left";
    titleElement.innerHTML = title;

    header.appendChild(xOutBtn);
    header.appendChild(titleElement);

    var body = document.createElement("div");
    body.className = "modal-body";

    var modalBodyContent = bodyContent;

    if (bodyContent === null) {
      modalBodyContent =
        '<div class="text-center"><div class="loader add-padding"></div></div>';
    } else if (typeof bodyContent == "function") {
      modalBodyContent = bodyContent();
    }

    if (typeof modalBodyContent == "string") {
      body.innerHTML = modalBodyContent;
    } else if (typeof modalBodyContent == "object") {
      body.appendChild(modalBodyContent);
    }

    content.appendChild(body);

    if (includeCloseBtn) {
      var footer = document.createElement("div");
      footer.className = "modal-footer";
      var closeBtn = document.createElement("button");
      closeBtn.type = "button";
      closeBtn.className = "btn btn-primary";
      closeBtn.setAttribute("data-dismiss", "modal");
      closeBtn.innerHTML = "Done";
      footer.appendChild(closeBtn);
      content.appendChild(footer);
    }

    document.body.appendChild(modal);
    var modalSelector = $(modal);
    modalSelector.modal("show");
    modalSelector.on("hidden.bs.modal", function () {
      $(this).remove();
    });

    return modal;
  },

  displayNotificationSlide: function (key, message, type = "danger") {
    if ($("#chshErrorsContainer-" + key).length > 0) {
      return;
    }
    if ($("div.rootContainerPanelMainContent").length === 0) {
      this.bootstrapAlert(key, message);

      return;
    }

    const $errorsContainer = $(
      `
<div class="alert-several add-padding-bottom add-padding-top chshErrorsContainer notification_slide" id="chshErrorsContainer-` +
        key +
        `">
    <ul class="alert alert-` +
        type +
        ` chshErrorsContainer-errorMessages" id="chshErrorsContainer-errorMessages-` +
        key +
        `">
      <li> ` +
        message +
        ` </li>
  </ul>
</div>
`
    );

    $("div.rootContainerPanelMainContent").first().prepend($errorsContainer);
    window.NotificationSlide.init($errorsContainer);
  },

  //Open a modal link
  linkOpenModal: function (
    linkHref,
    dataTarget,
    requestType = "GET",
    postData = {},
    replaceContentSelector = ".modal-content"
  ) {
    if (linkHref === undefined || linkHref === "#") {
      //Must be a static modal, load by data target
      var modalSelectorStatic = $(dataTarget);
      modalSelectorStatic.modal("show");
    } else if (linkHref.indexOf("#") === 0) {
      //Must be a static modal defined by href, load by href
      var modalSelectorStaticHref = $(linkHref);
      modalSelectorStaticHref.modal("show");
    } else {
      //Must be a dynamic modal, ajax load and show
      var modalSelectorDynamic = $(dataTarget);
      modalSelectorDynamic.modal("show");

      const startLoadTime = Date.now();
      const minSpinTime = 200;

      $.ajax({
        url: linkHref,
        type: requestType,
        data: requestType === "POST" ? postData : null,
        dataType: "text",
        success: function (data) {
          window.setTimeout(
            () => {
              modalSelectorDynamic.find(replaceContentSelector).html(data);
              CoreScripts.setupTablesorts();
              CoreScripts.makeTablesClickable();
            },
            Math.min(minSpinTime - (Date.now() - startLoadTime), minSpinTime)
          );
        },
        error: function (data) {
          // console.log(data);

          //Handle redirect responses with a _redirectTo set
          if (data.getResponseHeader("_redirectTo") !== undefined) {
            modalSelectorDynamic.modal("hide");
            CoreScripts.openUrl(data.getResponseHeader("_redirectTo"));

            return;
          }

          // display error login prompt as slide notification
          if (data.status === 401) {
            modalSelectorDynamic.modal("hide");
            CoreScripts.displayNotificationSlide(
              "LoginPrompt",
              "Please login to continue",
              "danger"
            );
            window.scrollTo(0, 0);

            return;
          }

          modalSelectorDynamic.find(replaceContentSelector).html(
            `
                      <div class="modal-header">
                          <button type="button" class="close" data-dismiss="modal">&times;</button>
                          <h4 class="modal-title">An Error Occurred</h4>
                      </div>
                      <div class="modal-body">
                          <p class="text-danger">PUT_THE_ERROR_CONTENT_HERE</p>
                      </div>
                      <div class="modal-footer clearfix">
                          <div class="pull-right no-padding-right text-right">
                              <button type="button" class="btn btn-default" data-dismiss="modal" id="modal-dismiss">Dismiss</button>
                          </div>
                          <div class="clearfix"></div>
                      </div>
                  `.replace(
              "PUT_THE_ERROR_CONTENT_HERE",
              data.responseText ||
                "An unknown error occurred. Please Contact Support if this error persists!"
            )
          );
        },
      });
    }
  },

  // Helper method used by makeTablesClickable to handle an element on a table being clicked
  handleTableClickEvent: function (element, event) {
    const $originalTarget = $(event.target || event.originalEvent.target);
    const $nearestClickabilityClass = $originalTarget.closest(
      ".chshTableUnclickable, .chshTableClickable"
    );

    if (
      $nearestClickabilityClass !== undefined &&
      $nearestClickabilityClass.hasClass("chshTableUnclickable")
    ) {
      //Prevent random clicks on unclickable cells from opening table-row modals
      if (
        element !== $originalTarget[0] &&
        $(element).attr("data-toggle") != null
      ) {
        event.stopPropagation();
      }

      return true;
    }

    //Only process click if the user is not selecting some text
    if (window.getSelection().toString().length === 0) {
      CoreScripts.elementOpenTarget($(element), event);
    }

    return false;
  },

  //Make tables clickable - should be called after doc ready
  makeTablesClickable: function () {
    //Tracking key - will be added after made clickable so we don't accidentally do it twice
    const trackKey = "ClkSetCh1";
    const trackVal = "1";
    //Handle Table Rows with Hrefs
    $("table:not(.unclickable) tbody tr[href]:not([" + trackKey + "])")
      .on("click", function (event) {
        return CoreScripts.handleTableClickEvent(this, event);
      })
      .attr(trackKey, trackVal);
    //Handle Table Cells with Hrefs
    $("table:not(.unclickable) tbody td[href]:not([" + trackKey + "])")
      .on("click", function (event) {
        return CoreScripts.handleTableClickEvent(this, event);
      })
      .attr(trackKey, trackVal);
  },

  /* Null coalesce selectors until a non-null is found */
  nullCoalesceSelectors: function (selectors) {
    var outputValue = null;
    $.each(selectors, function (selectorKey) {
      const selector = selectors[selectorKey];
      if (selector == null || selector === undefined || selector.length === 0) {
        return true;
      }
      outputValue = selector;

      return false;
    });

    return outputValue;
  },

  //Open a url in the current window
  openUrl: function (url) {
    window.location.href = CoreScripts.decodeHtmlEntities(url);
  },

  //Open a url in a new window
  openUrlInNewWindow: function (url) {
    var win = window.open(url, "_blank");
    win.focus();
  },

  //Replaces current url in browser history
  replaceCurrentUrlInBrowserHistory: function (
    replacementUrl,
    replacementTitle
  ) {
    if (!window.history.replaceState) {
      return false;
    }
    replacementTitle = replacementTitle || document.title;
    if (window.location.href !== replacementUrl) {
      window.history.replaceState({}, replacementTitle, replacementUrl);
    }

    return true;
  },

  //Changes a parameter int the current url in browser history
  replaceQueryStringParameterInCurrentUrl: function (key, value) {
    const uri = CoreScripts.replaceQueryStringParameterInUrl(
      window.location.href,
      key,
      value
    );
    CoreScripts.replaceCurrentUrlInBrowserHistory(uri);
  },

  //Changes a parameter in a url and returns the altered url
  replaceQueryStringParameterInUrl: function (uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf("?") !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, "$1" + key + "=" + value + "$2");
    } else {
      return uri + separator + key + "=" + value;
    }
  },

  // Attaches CSRF Tokens in an encrypted form to all ajax requests to authenticate them.
  setupAjaxRequestCsrfAttachment: function () {
    if ($.data(document, "attachingCsrfTokenToAjaxRequests") === true) {
      return;
    }

    var token = $('meta[name="csrf-token"]').attr("content");
    $.ajaxSetup({
      headers: {
        "X-CSRF-TOKEN": token,
      },
    });
    $(document).on("ajaxSend", function (event, jqxhr, settings) {
      jqxhr.setRequestHeader("X-CSRF-TOKEN", token);
    });

    $.data(document, "attachingCsrfTokenToAjaxRequests", true);
  },

  // Attaches CSRF Tokens in an encrypted form to all ajax requests to authenticate them.
  setupSelect2Focusing: function () {
    if ($.data(document, "focusingOnClickSelect2") === true) {
      return;
    }

    // hack to fix jquery 3.6 focus security patch that bugs auto search in select-2
    $(document).on("select2:open", (e) => {
      const target = e.target;
      const selectId = target
        .getAttribute("data-select2-id")
        .replace(/[[{(\]})\s']+/g, "");

      $(
        ".select2-search__field[aria-controls='select2-" +
          selectId +
          "-results']"
      ).each(function (key, value) {
        value.focus();
      });
    });

    $.data(document, "focusingOnClickSelect2", true);
  },

  // Set up chosen selects on the page.
  setupHelpIcons: function () {
    //Tracking key - will be added after made equal height so we don't accidentally do it twice
    const trackKey = "HlpIcnSet1";
    const trackVal = "1";

    function reportGraphHelpDocs(type) {
      switch (type) {
        case "shiftDaily":
          return "This graph shows a shift's daily performance.";
        case "hospitalComparisonGroup":
          return "This graph shows how this group's performance stacks up to the whole facility's performance each week.";
        case "complianceLastDays":
          return "This graph shows daily performance, in percent.";
        case "unitComplianceByGroup":
          return "This graph shows the performance of groups while working in this unit.";
        default:
          return (
            "Oops! We're missing help documentation for " +
            type +
            ". If you're seeing this text, please contact support and let us know. :)"
          );
      }
    }

    const elementsNeedingHelpText = $(
      ".highcharts-help:not([" + trackKey + "])"
    );
    if (elementsNeedingHelpText.length > 0) {
      elementsNeedingHelpText.attr(trackKey, trackVal);

      elementsNeedingHelpText.each(function () {
        const helpText = $(this).attr("data-help-text");
        let helpTextString = null;
        if (helpText) {
          helpTextString = reportGraphHelpDocs(helpText);
        } else {
          const helpTextStringExplicit = $(this).attr("data-help-text-string");
          if (helpTextStringExplicit) {
            helpTextString = helpTextStringExplicit;
          }
        }

        if (helpTextString) {
          $(this).html(
            '<button class="btn btn-sm btn-default highcharts-help-icon" data-toggle="tooltip" data-placement="top" title="' +
              helpTextString +
              '"><i class="fa fa-lg fa-info-circle"></i></button>'
          );
        }
      });
    }

    const elementsNeedingTooltip = $(
      '[data-toggle="tooltip"]:not([' + trackKey + "])"
    );
    if (elementsNeedingTooltip.length > 0) {
      elementsNeedingTooltip.attr(trackKey, trackVal);

      const elementsNeedingTooltipCasted = elementsNeedingTooltip;
      elementsNeedingTooltipCasted.tooltip();
    }
  },

  // Set up chosen selects on the page.
  setupChosenSelects: function () {
    //Activate Chosen Selects
    var chosenSelects = $(".chosen-select").filter(function () {
      //Only setup chosen selects that aren't already set up
      return $(this).data("chosen") === undefined;
    });

    if (chosenSelects.length > 0) {
      chosenSelects
        .on("chsh:chosenCloseSearchKeyboard", function () {
          //Hide search keyboard after item select for chosen single-selects (specifically needed for install tool iPad)
          var $chosenSearchInput = $(this)
            .parent()
            .find(".chosen-container-single .chosen-search-input");
          if ($chosenSearchInput.length > 0) {
            window.setTimeout(function () {
              $chosenSearchInput.trigger("blur");
            }, 0);
            window.setTimeout(function () {
              $chosenSearchInput.trigger("blur");
            }, 1);
          }
        })
        .on("chosen:showing_dropdown", function () {
          //Close other chosen selects
          $(".chosen-select")
            .not(this)
            .trigger("chosen:close")
            .trigger("chsh:chosenCloseSearchKeyboard");

          //Close other select 2's
          $("select[data-select2-id]").not(this).select2("close");
        })
        .on("chosen:hiding_dropdown", function () {
          $(this).trigger("chsh:chosenCloseSearchKeyboard");
        })
        .on("chosen:ready", function (evt, data) {
          $(this)
            .attr("tabindex", "-1")
            .css("position", "absolute")
            .css("z-index", "-9999")
            .css("opacity", 0)
            .css("max-width", "100%")
            .css("max-height", "100%")
            // .css('top', '0') Allow top to line up with natural position
            // .css('bottom', '0')
            // .css('left', '0')
            // .css('right', '0')
            .show();
        });

      //Attributes to configure all chosens - the disable search flag is overwritten below to disable searching some chosens
      const chosenAttributes = {
        disable_search: false,
        display_selected_options: false,
        enable_split_word_search: true,
        hide_results_on_select: true,
        include_group_label_in_selected: true,
        no_results_text: "No Results",
        search_contains: true,
      };

      var searchableChosenSelects = chosenSelects.not(".chosen-unsearchable");
      if (searchableChosenSelects.length > 0) {
        searchableChosenSelects.chosen(chosenAttributes);
      }

      var unsearchableChosenSelects = chosenSelects.filter(
        ".chosen-unsearchable"
      );
      if (unsearchableChosenSelects.length > 0) {
        var unsearchableChosenAttributes =
          CoreScripts.cloneDeep(chosenAttributes);
        unsearchableChosenAttributes.disable_search = true;

        unsearchableChosenSelects.chosen(unsearchableChosenAttributes);
      }
    }
  },

  /* Setup equal height elements */
  setupEqualHeightElements: function () {
    //Tracking key - will be added after made equal height so we don't accidentally do it twice
    const trackKey = "EquHtSet1";
    const trackVal = "1";

    //Equal Height Panels
    const equalHeightPanels = $(".equal-height-panels:not([" + trackKey + "])");
    if (equalHeightPanels.length > 0) {
      equalHeightPanels.find(".panel").matchHeight();
      equalHeightPanels.attr(trackKey, trackVal);
    }

    //Equal Height Wells
    const equalHeightWells = $(".equal-height-wells:not([" + trackKey + "])");
    if (equalHeightWells.length > 0) {
      equalHeightWells.find(".well").matchHeight();
      equalHeightWells.attr(trackKey, trackVal);
    }

    //Equal Height Panel Headings
    const equalHeightPanelHeadings = $(
      ".equal-height-panel-headings:not([" + trackKey + "])"
    );
    if (equalHeightPanelHeadings.length > 0) {
      equalHeightPanelHeadings.find(".panel-heading").matchHeight();
      equalHeightPanelHeadings.attr(trackKey, trackVal);
    }

    //Equal Height Btns
    const equalHeightBtns = $(".equal-height-btns:not([" + trackKey + "])");
    if (equalHeightBtns.length > 0) {
      equalHeightBtns.find(".btn").matchHeight();
      equalHeightBtns.attr(trackKey, trackVal);
    }

    //Globals - Highcharts wrappers in equal height wells should have graphs fill them
    const bodyGlobal = $("body:not([" + trackKey + "])");
    if (
      bodyGlobal.length > 0 &&
      $(".equal-height-wells .highcharts-wrapper").length > 0
    ) {
      $.fn.matchHeight._beforeUpdate = function () {
        $(".equal-height-wells .highcharts-wrapper").css("height", "400px");
      };
      $.fn.matchHeight._afterUpdate = function () {
        $(".equal-height-wells .highcharts-wrapper").css("height", "100%");
      };
    }
  },

  /* Setup links to modals to prevent default Bootstrap Behavior */
  setupModalLinks: function () {
    //Tracking key - will be added after made clickable so we don't accidentally do it twice
    const trackKey = "ClkSetCh1";
    const trackVal = "1";

    //Suppress default bootstrap modal behavior (deprecated in 3.3, removed in v4)
    $("[data-toggle='modal']:not([" + trackKey + "])").attr(
      "data-remote",
      "false"
    );

    //Setup Modal Links - setup anchors and buttons
    var modalLinkAnchors = $('a[data-toggle="modal"]:not([' + trackKey + "])");
    prepareModalLinks(modalLinkAnchors);
    var modalLinkButtons = $(
      'button[data-toggle="modal"]:not([' + trackKey + "])"
    );
    prepareModalLinks(modalLinkButtons);

    //Prepare links to modals for clicks to be handled correctly
    function prepareModalLinks(modalLinks) {
      //Setup click behavior
      modalLinks.on("click", function (event) {
        event.preventDefault();
        event.stopPropagation();
        CoreScripts.elementOpenTarget($(this), event);
      });

      modalLinks.attr(trackKey, trackVal);
    }
  },

  /* Setup loading spinner and modal destroy on dismiss */
  setupModalShowHide: function () {
    if ($.data(document, "handlingModalShowHideRetain") === true) {
      return;
    }

    //Purge old modal contents on hide
    $(document).on("hidden.bs.modal", ".modal", function (element) {
      if (!$(element.target || this).hasClass("retain")) {
        //if modal has doesn't have retain class, purge
        $(element.target || this)
          .removeData("bs.modal")
          .find(".modal-content")
          .empty();
      }

      //Fix scrollbars if another modal is still visible
      if ($(".modal:visible").length > 0) {
        $(document.body).addClass("modal-open");

        return false;
      }
    });

    //Setup modal on loaded calls
    $(document).on("show.bs.modal", ".modal", function (element) {
      //Show Loading Screen when Modal Loading
      var $modalTarget = $(element.target || this);
      if (!$modalTarget.hasClass("retain")) {
        $modalTarget
          .find(".modal-content")
          .html(
            '<div class="modal-body text-center"><div class="loader add-padding"></div></div>'
          );
      }

      //Set the z index for newly opened modals so each new ioe opens on top of the last
      var _window = window;
      _window.modalHandlerZIndex = _window.modalHandlerZIndex || 10000;
      _window.modalHandlerZIndex += 1;
      $modalTarget.css("z-index", _window.modalHandlerZIndex);
    });

    $.data(document, "handlingModalShowHideRetain", true);
  },

  setupNavbar: function () {
    //Tracking key - will be added after setup so we don't accidentally do it twice
    const trackKey = "NavbarSetCC2";
    const trackVal = "1";

    //Navbar
    var $subMenus = $(".dropdown-submenu:not([" + trackKey + "])");
    if ($subMenus.length > 0) {
      $subMenus
        .each(function (idx, el) {
          var $el = $(el);
          var $dropdownToggle = $el.find(".dropdown-submenu-toggle");
          $dropdownToggle
            .on("click", function (event) {
              CoreScripts.setupNavbarSupportDoChshShowNavDrop($el);
              event.stopPropagation();

              return false;
            })
            .on("mouseenter", function () {
              CoreScripts.setupNavbarSupportDoChshShowNavDrop($el);

              return false;
            });
          $el
            .on("mouseleave", function () {
              CoreScripts.setupNavbarSupportDoChshHideNavDrop($el);

              return false;
            })
            .on("chshHideNavDrop", function () {
              CoreScripts.setupNavbarSupportDoChshHideNavDrop($el);

              return false;
            })
            .on("chshShowNavDrop", function () {
              CoreScripts.setupNavbarSupportDoChshShowNavDrop($el);

              return false;
            });
        })
        .attr(trackKey, trackVal);
    }

    var $dropdownToggles = $(".dropdown-toggle:not([" + trackKey + "])");
    if ($dropdownToggles.length > 0) {
      $dropdownToggles
        .on("click", function () {
          $(".submenu").each(function () {
            CoreScripts.setupNavbarSupportDoChshHideNavDrop($(this));
          });
        })
        .attr(trackKey, trackVal);
    }
  },

  setupNavbarSupportDoChshShowNavDrop: function ($targettedElement) {
    var $ULs = $targettedElement.find("ul");
    if ($ULs.length > 0) {
      $(".submenu")
        .not($targettedElement)
        .each(function () {
          CoreScripts.setupNavbarSupportDoChshHideNavDrop($(this));
        });
      $ULs.css("display", "block");
    }
    var $As = $targettedElement.find("a");
    $As.first().trigger("focus");
  },

  setupNavbarSupportDoChshHideNavDrop: function ($targettedElement) {
    var $ULs = $targettedElement.find("ul");
    if ($ULs.length > 0) {
      $ULs.css("display", "none");
    }
    var $As = $targettedElement.find("a");
    if ($As.length > 0) {
      $As.trigger("blur");
    }
  },

  /* Setup tablesorters on table view */
  setupTablesorts: function () {
    //Tablesorters

    //Tracking key - will be added after setup so we don't accidentally do it twice
    const trackKey = "TablesorterSetCC2";
    const trackVal = "1";

    var $tableSorters = $(".tablesorter:not([" + trackKey + "])");
    if ($tableSorters.length > 0) {
      $tableSorters
        .each(function (idx, el) {
          var $el = $(el);
          var widgets = ["zebra", "uitheme"];
          if (!$el.hasClass("tablesorter-nosave")) {
            widgets.push("saveSort");
          }
          $el
            .tablesorter({
              theme: "bootstrap",
              widthFixed: true,
              showProcessing: true,
              headerTemplate: "{content} {icon}",
              widgets: widgets,
              sortList: [[1, 0]],
            })
            .trigger("tableSorterInitialized");
        })
        .attr(trackKey, trackVal);
    }
  },
};

export default CoreScripts;
