import "./Scss/style.scss";
import RootLayout from "./Pages/RootLayout/RootLayout";
import ErrorPage from "./Pages/Error/Error";

import { GroupsLoader } from "./loaders/Groups/GroupsLoader";
import { UnitsLoader } from "./loaders/Units/UnitsLoader";
import { StaffLoader } from "./loaders/Staff/StaffLoader";
import { MyReportLoader } from "./loaders/MyReport/MyReportLoader";
import { ExecutiveLoader } from "./loaders/Executive/ExecutiveLoader";
import { AssetsLoader } from "./loaders/Assets/AssetsLoader";
import { SignedReportLoader } from "./loaders/SignedReports/SingedReportLoader";
import { IndividualAssetsLoader } from "./loaders/IndividualAssets/IndividualAssetsLoader";
import { RtibLoader } from "./loaders/Rtib/RtibLoader";
import { OtibLoader } from "./loaders/Otib/OtibLoader";
import StaffView from "./Pages/Staff/StaffView";
import MyReportView from "./Pages/MyReport/MyReportView";
import GroupsView from "./Pages/Groups/GroupsView";
import UnitsView from "./Pages/Units/UnitsView";
import ExecutiveView from "./Pages/Executive/ExecutiveView";
import AssetsView from "./Pages/Assets/AssetsView";
import Rtib from "./Pages/Rtib/Rtib";
import Otib from "./Pages/Otib/Otib";
import RoundingView from "./Pages/Rounding/RoundingView";
import InitialNavigationDisplay from "./Components/InitialNavigationDisplay/InitialNavigationDisplay";
import IndividualAssetView from "./Pages/IndividualAsset/IndividualAsset";
import { IndividualStaffLoader } from "./loaders/IndividualStaffPage/IndividualStaffPageLoader";
import IndividualStaffView from "./Pages/IndividualStaff/IndividualStaff";
import { ModuleThemeProvider } from "./contexts/ModuleTheme/moduleThemes";
import { RoundingLoader } from "./loaders/NursingInsights/RoundingLoader";
import { RoomsOverviewLoader } from "./loaders/NursingInsights/RoomsOverviewLoader";
import { StaffOverviewLoader } from "./loaders/NursingInsights/StaffOverviewLoader";
import NewCase from "./Pages/NewCase/NewCase";
import Cases from "./Pages/Cases/Cases";
import PositiveStaffCase from "./Pages/PositiveCase/PositiveStaffCaseView";
import PositiveRoomCase from "./Pages/PositiveRoomCase/PositiveRoomCaseView";
import { CaseOptionsLoader } from "./loaders/ContactTracing/CaseOptionsLoader";
import { CasesLoader } from "./loaders/Cases/CasesLoader";
import { PositiveStaffCaseLoader } from "./loaders/ContactTracing/PositiveStaffCaseLoader";
import { PositiveRoomCaseLoader } from "./loaders/ContactTracing/PositiveRoomCaseLoader";
import { Navigate } from "react-router";
import OverviewPage from "./Pages/Overview/Overview";
import OverviewLayout from "./Pages/OverviewLayout/OverviewLayout";
import VisitOverview from "./Pages/VisitOverview/VisitOverview";
import RoomsOverviewView from "./Pages/RoomsOverview/RoomsOverview";
import StaffOverviewView from "./Pages/StaffOverview/StaffOverview";
import { RoomsOverviewSignedLoader } from "./loaders/NursingInsights/RoomsOverviewSignedLoader";
import { StaffOverviewSignedLoader } from "./loaders/NursingInsights/StaffOverviewSignedLoader";
import RoomsHistoryView from "./Pages/RoomsHistory/RoomsHistoryView";
import { RoomsHistoryLoader } from "./loaders/NursingInsights/RoomsHistoryLoader";
import MaintenancePage from "./Pages/Maintenance/Maintenance";

const routesConfig = [
  {
    path: "/maintenance",
    element: <MaintenancePage />,
    caseSensitive: false,
  },
  {
    element: (
      <ModuleThemeProvider>
        <OverviewLayout />
      </ModuleThemeProvider>
    ),
    errorElement: <ErrorPage />,
    children: [{ path: "/", element: <OverviewPage />, index: true }],
  },
  {
    element: (
      <ModuleThemeProvider>
        <RootLayout />
      </ModuleThemeProvider>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/HH/",
        element: <InitialNavigationDisplay />,
        caseSensitive: false,
      },
      {
        path: "/HH/executive/",
        caseSensitive: false,
        element: <ExecutiveView />,
        loader: async ({ request }) =>
          SignedReportLoader(request, ExecutiveLoader),
      },
      {
        caseSensitive: false,
        path: "/HH/groups/",
        element: <GroupsView />,
        loader: async ({ request }) =>
          SignedReportLoader(request, GroupsLoader),
      },
      {
        caseSensitive: false,
        path: "/HH/units/",
        element: <UnitsView />,
        loader: async ({ request }) => SignedReportLoader(request, UnitsLoader),
      },
      {
        caseSensitive: false,
        path: "/HH/staff/:id",
        element: <IndividualStaffView />,
        loader: IndividualStaffLoader,
      },
      {
        path: "/HH/staff/",
        caseSensitive: false,
        element: <StaffView />,
        loader: async ({ request }) => SignedReportLoader(request, StaffLoader),
      },
      {
        caseSensitive: false,
        path: "/HH/RTIB/",
        element: <Rtib />,
        loader: async ({ request }) => SignedReportLoader(request, RtibLoader),
      },
      {
        caseSensitive: false,
        path: "/HH/OTIB/",
        element: <Otib />,
        loader: async ({ request }) => SignedReportLoader(request, OtibLoader),
      },
      {
        caseSensitive: false,
        path: "/HH/my-report/",
        element: <MyReportView />,
        loader: async ({ request }) =>
          SignedReportLoader(request, MyReportLoader),
      },
      // asset tracking
      {
        path: "/AT/",
        element: <Navigate to="/AT/assets" />,
        caseSensitive: false,
      },
      {
        path: "/AT/assets",
        caseSensitive: false,
        element: <AssetsView />,
        loader: AssetsLoader,
      },
      {
        path: "/AT/assets/:id",
        caseSensitive: false,
        element: <IndividualAssetView />,
        loader: IndividualAssetsLoader,
      },
      // nursing insights
      {
        path: "/NI/",
        element: <Navigate to="/NI/rounding" />,
        caseSensitive: false,
      },
      {
        path: "/NI/rounding",
        caseSensitive: false,
        element: <RoundingView />,
        loader: RoundingLoader,
      },
      {
        path: "/NI/rooms-history",
        caseSensitive: false,
        element: <RoomsHistoryView />,
        loader: RoomsHistoryLoader,
      },
      {
        path: "/NI/visit-overview/",
        caseSensitive: false,
        element: <VisitOverview />,
        children: [
          {
            index: true,
            element: <Navigate to="rooms" replace />,
          },
          {
            path: "/NI/visit-overview/rooms/",
            caseSensitive: false,
            element: <RoomsOverviewView />,
            loader: async ({ request }) =>
              RoomsOverviewSignedLoader(request, RoomsOverviewLoader),
          },
          {
            path: "/NI/visit-overview/staff/",
            caseSensitive: false,
            element: <StaffOverviewView />,
            loader: async ({ request }) =>
              StaffOverviewSignedLoader(request, StaffOverviewLoader),
          },
        ],
      },
      // contact tracing
      {
        path: "/CT/",
        element: <Navigate to="/CT/new-case" />,
        caseSensitive: false,
      },
      {
        path: "/CT/new-case",
        caseSensitive: false,
        element: <NewCase />,
        loader: CaseOptionsLoader,
      },
      {
        path: "/CT/cases",
        caseSensitive: false,
        element: <Cases />,
        loader: CasesLoader,
      },
      {
        path: "/CT/positive-staff-case/:id",
        caseSensitive: false,
        element: <PositiveStaffCase />,
        loader: PositiveStaffCaseLoader,
      },
      {
        path: "/CT/positive-room-case/:id",
        caseSensitive: false,
        element: <PositiveRoomCase />,
        loader: PositiveRoomCaseLoader,
      },
    ],
  },
];

export default routesConfig;
