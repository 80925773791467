import { Bubble } from "react-chartjs-2";
import { AverageCompliance } from "../../types/types";
import { getBubbleChartData } from "./getBubbleChartData";
import { ChartOptions } from "chart.js";
import Cards from "../UI/Cards/Cards";
import { useState, useEffect } from "react";
import MissingData from "../MissingData/MissingData";
import { missingDataMessage, missingDataAction } from "../../constants";
import Graph from "@untitled-ui/icons-react/build/cjs/LineChartUp01";
import { useModuleTheme } from "../../contexts/ModuleTheme/moduleThemes";

interface BubbleChartProps {
  data: AverageCompliance[];
  userInfo?: Object;
  title?: string;
  updateRowDataId: (rowData: number) => void;
  rowClickAction: () => void;
}

const BubbleChart = (props: BubbleChartProps) => {
  const { theme } = useModuleTheme();
  const [rowDataValue, setRowData] = useState<number | null>(null);

  useEffect(() => {
    if (rowDataValue) {
      props.updateRowDataId(rowDataValue);
      props.rowClickAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowDataValue]);

  const options: ChartOptions<"bubble"> = {
    onHover: (event, activeElements) => {
      (event?.native?.target as HTMLElement).style.cursor =
        activeElements?.length > 0 ? "pointer" : "auto";
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: props.title,
        font: {
          size: 24,
        },
        color: "#000",
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const performance = context.parsed.y;
            const opportunities = context.parsed.x;
            return `Performance: ${performance}%\nOpportunities: ${opportunities}`;
          },
        },
      },
    },
    onClick: (chartElements) => {
      const rowData = (chartElements as any).chart.tooltip.title[0];
      if (theme === "hand-hygiene") {
        // eslint-disable-next-line array-callback-return
        props.data.map((item) => {
          if (item.name === rowData) {
            setRowData(item.provider_id as any);
          }
          props.updateRowDataId(rowDataValue as number);
          props.rowClickAction();
        });
      }
    },
    scales: {
      x: {
        min: 0,
        title: {
          text: "Opportunities",
          display: true,
          font: {
            size: 18,
          },
        },
      },
      y: {
        min: 0,
        title: {
          text: "Performance",
          display: true,
          font: {
            size: 18,
          },
        },
      },
    },
  };

  const { labels, mappedAndFilteredData, backgroundColors } =
    getBubbleChartData(props.data);

  const data = {
    labels: labels,
    datasets: [
      {
        data: mappedAndFilteredData,
        backgroundColor: backgroundColors,
      },
    ],
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Cards className="padding-top" headerLeft={<h2>{props.title}</h2>}>
      {data.datasets[0].data.length === 0 ? (
        <div className="center-content">
          <MissingData
            message={missingDataMessage}
            action={missingDataAction}
          />
          <Graph />
        </div>
      ) : (
        <div style={{ height: "750px" }} id="pdf-graph">
          <Bubble id="graph" data={data} options={options} key={width} />
        </div>
      )}
    </Cards>
  );
};
export default BubbleChart;
