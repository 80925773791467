import { complianceThresholds } from "../../constants";

function ComplianceBucketButtons({
  selectedButton,
  onSelectButton,
  buttonToggled,
  isHeatmap,
}) {
  const handleClick = (id: number) => {
    if (selectedButton === id) {
      onSelectButton(null);
    } else {
      onSelectButton(id);
    }
  };

  const metGoalButtonId = 4;

  const handleGoalCardClick = () => {
    handleClick(metGoalButtonId);
    buttonToggled();
  };

  return (
    <div>
      {Object.entries(complianceThresholds).map(([_key, button]) => (
        <button
          data-testid={"compliance-button-" + button.id}
          key={button.id}
          className={
            selectedButton === button.id
              ? "active-legend-button margin-right"
              : "margin-right"
          }
          onClick={() => handleClick(button.id)}
        >
          <div className={`compliance ${button.className}`}></div>
          {button.label}
        </button>
      ))}
      {isHeatmap && (
        <button
          data-testid={"compliance-button-" + 4}
          key={4}
          className={
            selectedButton === metGoalButtonId
              ? "active-legend-button margin-right"
              : "margin-right"
          }
          onClick={() => handleGoalCardClick()}
        >
          <div className="compliance">
            <img
              className="goal-compliance"
              src="/goal-badge.png"
              alt="goal badge"
            />
          </div>
          Met Goal
        </button>
      )}
    </div>
  );
}

export default ComplianceBucketButtons;
