import { oneWeekDateRange } from "../../constants";
import useRoomsHistoryModel from "../../model/RoomsHistory/useRoomsHistoryModel";
import { transformFilters } from "../../transformers/utils";
import { RoomsHistory } from "../../types/types";
import { parseDateToMidnight } from "../../utils";

const useRoomsHistoryViewModel = () => {
  const { roomsHistoryData } = useRoomsHistoryModel();
  const filters = transformFilters(roomsHistoryData.params);
  let startDate, endDate;
  const dateRange = roomsHistoryData.params?.date_range?.value;

  if (!dateRange) {
    startDate = oneWeekDateRange.startDate;
    endDate = oneWeekDateRange.endDate;
  } else {
    startDate = new Date(parseDateToMidnight(dateRange[1]));
    endDate = new Date(parseDateToMidnight(dateRange[2]));
  }
  const transformedRoomsHistoryData: RoomsHistory = {
    ...roomsHistoryData,
    excelUrl: roomsHistoryData?.export,
    startDate: startDate ? new Date(startDate) : null,
    endDate: endDate ? new Date(endDate) : null,
    filters,
    uuid: roomsHistoryData?._report?.uuid,
  };

  return {
    transformedRoomsHistoryData,
  };
};

export default useRoomsHistoryViewModel;
