import { createServer, Factory, Model } from "miragejs";
import { faker } from "@faker-js/faker";
import { format, sub } from "date-fns";
import {
  unitReportEndpoint,
  groupReportEndpoint,
  executiveDashboardReportEndpoint,
  userReportEndpoint,
  myReportEndpoint,
  assetsEndpoint,
  roundingEndpoint,
  roomsHistoryEndpoint,
  roomsOverviewSummaryEndpoint,
  roomsOverviewTableEndpoint,
  staffOverviewSummaryEndpoint,
  staffOverviewTableEndpoint,
  roomsOverviewParamsEndpoint,
} from "../constants";
import mockUnitReport from "./mockData/mockUnitReportData";
import mockGroupReportData from "./mockData/mockGroupReportData";
import mockExecutiveDashboardData from "./mockData/mockExecutiveDashboardData";
import mockUserReportData from "./mockData/mockUserReportData";
import mockMyReportData from "./mockData/mockMyReportData";
import { withTimezoneOffset } from "../test/utils";

const baseFilters = {
  // note, we are using camel case for naming! This is different from HH but a longer term - better practice solution.
  customer_ids: {
    options: {
      1: "Monsters Inc",
      2: "Ebenezer",
    },
    visible: true,
    reloading: {
      url: "",
      peer_filters: [],
    },
  },
  facility_ids: {
    options: {
      1: "Facility 1",
      2: "Facility 2",
    },
    visible: true,
    reloading: {
      url: "",
      peer_filters: [],
    },
  },
  unit_ids: {
    options: {
      1: "Unit 1",
      2: "Unit 2",
    },
    visible: true,
    reloading: {
      url: "",
      peer_filters: [],
    },
  },
};

const assetTrackingFilters = {
  ...baseFilters,
  asset_type_ids: {
    options: {
      1: "Pump",
      2: "Another Pump",
    },
    visible: true,
    reloading: {
      url: "",
      peer_filters: [],
    },
  },
  asset_model_ids: {
    options: {
      1: "Pump 123",
      2: "Pump 456",
    },
    visible: true,
    reloading: {
      url: "",
      peer_filters: [],
    },
  },
  room_config_ids: {
    options: {
      1: "Room 1",
      2: "Room 2",
    },
    visible: true,
    reloading: {
      url: "",
      peer_filters: [],
    },
  },
};

const roundingFilters = {
  ...baseFilters,
  room_config_ids: {
    options: {
      1: "Room 1",
      2: "Room 2",
    },
    visible: true,
    reloading: {
      url: "",
      peer_filters: [],
    },
  },
};

const visitOverviewFiltersBase = {
  ...baseFilters,
  date_range: {
    value: ["DD", "2024-06-13", "2024-08-22"],
    valueDisplay: "6/13/2024–8/22/2024",
  },
};

const roomsOverviewFilters = {
  ...visitOverviewFiltersBase,
  tag_ids: {
    options: {
      1: "Unit Type 1",
      2: "Unit Type 2",
    },
    visible: true,
    reloading: {
      url: "",
      peer_filters: [],
    },
  },
};

const exportUrl =
  "https://local.swipesense.test/report/de/98f6709c-47ba-44ea-ab56-5cc0c1285a7c?expires=1681928610&signature=7445ad23c621313d4b2a6b7d4901be413483d9fad6ee68bebcc08e1b91aa836e";

export function createMockServer({ environment = "test" } = {}) {
  let isInRoom = faker.datatype.boolean({ probability: 0.8 });
  let facilityId = faker.number.int({ min: 1, max: 1000 });
  let unitId = faker.number.int({ min: 1, max: 1000 });

  const createAggregateBreakdown = (dateRange) => ({
    percentage: faker.number.int({ min: 1, max: 100 }),
    dateRange,
  });

  const individualShiftPerformance = (name, dateRanges) => {
    return {
      name,
      performance: faker.number.int({ min: 1, max: 100 }),
      opportunities: faker.number.int({ min: 1, max: 200000 }),
      breakdownByDateRange: dateRanges.map(createAggregateBreakdown),
    };
  };

  const createShift = (name, minAvg, maxAvg) => {
    const mean = faker.number.int({ min: minAvg, max: maxAvg });
    return {
      name,
      mean,
    };
  };

  const generateVisits = () => {
    const visits = [];
    for (let i = 0; i < 30; i++) {
      const entry = faker.date.recent();
      const exit = faker.date.between({ from: entry, to: new Date() });
      const durationSeconds = Math.floor((exit - entry) / 1000) % 60;
      const overlapping = faker.datatype.boolean({ probability: 0.4 });
      const overlappingVisitDurationSeconds = overlapping
        ? Math.floor(Math.random() * 1000)
        : null;
      visits.push({
        entry: format(entry, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        exit: format(exit, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        timezone: "America/New_York",
        staff: {
          name: faker.person.firstName() + " " + faker.person.lastName(),
          type: faker.helpers.arrayElement(["RN", "CNA"]),
          group: faker.helpers.arrayElement(["Nurse", "CNA"]),
        },
        durationSeconds,
        overlapping,
        overlappingVisitDurationSeconds,
      });
    }
    return visits;
  };

  let server = createServer({
    models: {
      facility: Model,
      report: Model,
      asset: Model,
      shift: Model,
      customer: Model,
      model: Model,
      location: Model,
      unit: Model,
    },
    factories: {
      asset: Factory.extend({
        // Generate all the other asset attributes...
        // Then generate the related data:
        customer() {
          return {
            id: faker.number.int({ min: 1, max: 1000 }),
            name: faker.company.name(),
          };
        },
        model() {
          return {
            id: faker.number.int({ min: 1, max: 1000 }),
            name: faker.commerce.productName(),
            manufacturer: faker.company.name(),
            nickname: faker.person.firstName(),
            imageLocation: faker.image.url(),
          };
        },
        lastLocation() {
          return {
            facilityId: facilityId,
            facilityName: `facility-${facilityId}`,
            unitId: faker.number.int({ min: 1, max: 1000 }),
            unitName: `unit-${unitId}`,
            isInRoom: isInRoom,
            roomId: isInRoom ? faker.number.int({ min: 1, max: 1000 }) : null,
            roomName: isInRoom
              ? `room-${faker.number.int({ min: 1, max: 1000 })}`
              : null,
            nearestRoomId: isInRoom
              ? null
              : faker.number.int({ min: 1, max: 1000 }),
            nearestRoomName: isInRoom
              ? null
              : `room-${faker.number.int({ min: 1, max: 1000 })}`,
            xCoordinate: faker.number.int({ min: 1, max: 100 }),
            yCoordinate: faker.number.int({ min: 1, max: 100 }),
          };
        },
        roomEntries: [
          {
            facilityId: facilityId,
            facilityName: `facility-${facilityId}`,
            unitId: unitId,
            unitName: `unit-${unitId}`,
            roomId: isInRoom ? faker.number.int({ min: 1, max: 1000 }) : null,
            roomName: isInRoom
              ? `room-${faker.number.int({ min: 1, max: 1000 })}`
              : null,
            entryTime: {
              datetime: "2023-08-31T10:00:00.000Z",
              timezone: "America/Chicago",
            },
            exitTime: null,
            xCoordinate: faker.number.int({ min: 1, max: 100 }),
            yCoordinate: faker.number.int({ min: 1, max: 100 }),
          },
          {
            facilityId: facilityId,
            facilityName: `facility-${facilityId}`,
            unitId: unitId,
            unitName: `unit-${unitId}`,
            roomId: isInRoom ? faker.number.int({ min: 1, max: 1000 }) : null,
            roomName: isInRoom
              ? `room-${faker.number.int({ min: 1, max: 1000 })}`
              : null,
            entryTime: {
              datetime: "2023-07-28T10:00:00.000Z",
              timezone: "America/Chicago",
            },
            exitTime: {
              datetime: "2023-07-31T10:00:00.000Z",
              timezone: "America/Chicago",
            },
          },
          {
            facilityId: facilityId,
            facilityName: `facility-${facilityId}`,
            unitId: unitId,
            unitName: `unit-${unitId}`,
            roomId: isInRoom ? faker.number.int({ min: 1, max: 1000 }) : null,
            roomName: isInRoom
              ? `room-${faker.number.int({ min: 1, max: 1000 })}`
              : null,
            entryTime: {
              datetime: "2023-06-31T10:00:00.000Z",
              timezone: "America/Chicago",
            },
            exitTime: {
              datetime: "2023-07-15T10:00:00.000Z",
              timezone: "America/Chicago",
            },
          },
        ],
        assignedFacility() {
          let facilityId = faker.number.int({ min: 1, max: 1000 });
          return {
            id: facilityId,
            name: `facility-${facilityId}`,
          };
        },
        assignedUnit() {
          let unitId = faker.number.int({ min: 1, max: 1000 });
          return {
            id: unitId,
            name: `unit-${unitId}`,
          };
        },
        typeId: faker.number.int({ min: 1, max: 1000 }),
        typeName: faker.commerce.productName(),
        nickname: faker.commerce.productName(),
        serialNumber: faker.string.alphanumeric(10),
        isInUse: faker.datatype.boolean(),
        ownershipStatus: faker.helpers.arrayElement([
          "Owned",
          "Rented/Leased",
          "Other",
        ]),
        lastSeenAt: {
          datetime: () => withTimezoneOffset(faker.date.recent()),
          timezone: "America/Chicago",
        },
        export: exportUrl,
      }),
    },

    seeds(server) {
      server.createList("asset", 100);
    },

    routes() {
      this.urlPrefix = process.env.REACT_APP_AUTH_DOMAIN;

      this.get("/ajax/userAccess/current", (schema, request) => {
        return {
          user: {
            id: 1,
          },
          auth: {
            isCurrentAndFullyAuthenticated: true,
          },
          permissions: [
            "HH.report.group",
            "HH.report.unit",
            "HH.dashboard.executive",
            "HH.report.individualTrend",
            "HH.report.me",
            "assetTracking.view",
            // to do confirming names of these permissions
            "NI.report.places",
            "NI.report.roomsHistory",
            "NI.report.visits-overview",
            "CT.case.create",
            "CT.case.view",
          ],
          tokens: {
            csrf: "12345",
          },
        };
      });

      this.get(unitReportEndpoint, (schema, request) => {
        return {
          facilities: schema.facilities.all().models,
          ...mockUnitReport(),
        };
      });

      this.get(groupReportEndpoint, (schema, request) => {
        return {
          facilities: schema.facilities.all().models,
          ...mockGroupReportData(),
        };
      });

      this.get(executiveDashboardReportEndpoint, (schema, request) => {
        return {
          facilities: schema.facilities.all().models,
          ...mockExecutiveDashboardData(),
        };
      });

      this.get(userReportEndpoint, (schema, request) => {
        return {
          facilities: schema.facilities.all().models,
          ...mockUserReportData(),
        };
      });

      this.get(myReportEndpoint, (schema, request) => {
        return {
          facilities: schema.facilities.all().models,
          ...mockMyReportData(),
        };
      });

      this.get(assetsEndpoint, (schema) => {
        return {
          params: assetTrackingFilters,
          export: exportUrl,
          totalAssets: schema.assets.all().models.length,
          assets: schema.assets.all().models.map((asset) => asset.attrs),
        };
      });

      this.get(`${assetsEndpoint}/:id`, (schema, request) => {
        let id = request.params.id;
        return {
          params: {
            date_range: {
              value: ["TD", "2023-10-02 00:00:00", "2023-10-25 00:00:00"],
            },
          },
          asset: schema.assets.find(id),
        };
      });

      const shifts = [
        "All Shifts",
        "Day Shifts",
        "Night Shifts",
        "Weekday",
        "Weekend",
      ];
      const dateRanges = [];
      for (let i = 4; i > 0; i--) {
        const beginDate = format(sub(new Date(), { weeks: i }), "yyyy-MM-dd");
        const endDate = format(sub(new Date(), { weeks: i - 1 }), "yyyy-MM-dd");
        dateRanges.push(`${beginDate} to ${endDate}`);
      }

      this.get(roundingEndpoint, (schema) => {
        return {
          params: roundingFilters,
          export: exportUrl,
          allNodes: {
            totalPerformance: faker.number.int({ min: 1, max: 100 }),
            averages: [
              {
                title: "Average Time Between Rounds",
                shifts: shifts.map((shift, i) =>
                  createShift(shifts[i], 1000, 18000)
                ),
              },
              {
                title: "Nurse Room Time %",
                shifts: shifts.map((shift, i) =>
                  createShift(shifts[i], 1, 100)
                ),
              },
              {
                title: "Average Round Duration",
                shifts: shifts.map((shift, i) =>
                  createShift(shifts[i], 30, 500)
                ),
              },
            ],
          },
          aggregateData: [
            {
              node: {
                name: "Cool Place",
                type: "Room",
                unit: "Unit 1",
                facility: "Facility 1",
                shifts: shifts.map((shift) => {
                  return individualShiftPerformance(shift, dateRanges);
                }),
              },
            },
            {
              node: {
                name: "Cooler Place",
                type: "Room",
                unit: "Unit 2",
                facility: "Facility 2",
                shifts: shifts.map((shift) => {
                  return individualShiftPerformance(shift, dateRanges);
                }),
              },
            },
          ],
        };
      });

      this.get(roomsHistoryEndpoint, (schema) => {
        return {
          params: baseFilters,
          export: exportUrl,
          roomName: `Room ${faker.location.buildingNumber()}`,
          unitName: `Unit ${faker.location.buildingNumber()}`,
          facilityName: `Facility ${faker.location.buildingNumber()}`,
          visits: generateVisits(),
        };
      });

      this.get(roomsOverviewParamsEndpoint, (schema) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              params: roomsOverviewFilters,
            });
          }, 300);
        });
      });

      this.get(staffOverviewSummaryEndpoint, (schema) => {
        return new Promise((resolve) => {
          // call set timeout to return this data in 5 seconds:
          setTimeout(() => {
            resolve({
              summary: "some Staff summary data",
            });
          }, 3000);
        });
      });

      this.get(staffOverviewTableEndpoint, (schema) => {
        return new Promise((resolve) => {
          // call set timeout to return this data in 1 second:
          setTimeout(() => {
            resolve({
              rooms: "some Staff table data",
            });
          }, 500);
        });
      });

      this.get(roomsOverviewSummaryEndpoint, (schema) => {
        const averages = {
          visitLength: 360,
          timeAlone: 54,
          timeBetween: 7500,
        };
        return new Promise((resolve) => {
          // call set timeout to return this data in 5 seconds:
          setTimeout(() => {
            console.log("summary returning");
            resolve({
              averages,
            });
          }, 2000);
        });
      });

      this.get(roomsOverviewTableEndpoint, (schema) => {
        const paging = {
          page: 1,
          pageSize: 50,
          hasAdditionalPages: false,
        };
        const sort = {
          sortBy: "room.name",
          sortDesc: false,
        };

        const roomOverview1 = {
          room: {
            id: 1,
            name: "Room 1",
          },
          mostVisitedByUser: {
            id: 1,
            firstName: "John",
            lastName: "Deere",
          },
          visitLengthAvg: 480,
          visitCount: 15,
          longestVisit: 600,
        };

        const roomOverview2 = {
          room: {
            id: 2,
            name: "Room 2",
          },
          mostVisitedByUser: {
            id: 2,
            firstName: "Amelia",
            lastName: "Earhart",
          },
          visitLengthAvg: 245,
          visitCount: 12,
          longestVisit: 500,
        };

        const roomOverview3 = {
          room: {
            id: 3,
            name: "Room 3",
          },
          mostVisitedByUser: {
            id: 3,
            firstName: "Jack",
            lastName: "Black",
          },
          visitLengthAvg: 150,
          visitCount: 5,
          longestVisit: 780,
        };

        const roomOverview4 = {
          room: {
            id: 4,
            name: "Room 4",
          },
          mostVisitedByUser: null,
          visitLengthAvg: null,
          visitCount: null,
          longestVisit: null,
        };

        const roomOverviews = [
          roomOverview1,
          roomOverview2,
          roomOverview3,
          roomOverview4,
        ];
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              paging,
              sort,
              roomOverviews,
            });
          }, 1000);
        });
      });
      // Example of how to return an error:
      // this.get(groupReportEndpoint, (schema, request) => {
      //   // return a 403
      //   return new Response(
      //     403,
      //     {},
      //     {
      //       errors: [
      //         {
      //           status: 403,
      //           title: "Forbidden",
      //           detail: "You do not have permission to access this resource.",
      //         },
      //       ],
      //     }
      //   );
      // });

      this.post("/logout", (schema, request) => {});
    },
  });

  return server;
}
