import GenericLayer from "./GenericLayer";
import $ from "jquery";

export default class RoomLayer extends GenericLayer {
  get defaultMarkers() {
    // Load up pre-saved markers if present
    return this.chart.markers.room || {};
  }

  get defaultUnplacedMarkers() {
    /* Load up unplaced markers if present */
    return this.chart.unplacedMarkers.room || {};
  }

  /* What depth should the layer take (0 is bottom, inf is top) */
  get sortDepthIndex() {
    return 90;
  }

  get layerScale() {
    return this.chart.scales.room || 1;
  }

  setupElement(elementSel, elementInfo) {
    this.elementAddRotationControls(elementSel, 22, 22, 20, -30, 45);
    this.elementAddRotationControls(elementSel, 15, 15, 10, -20, 1).attr(
      "class",
      "visible-when-zoomed"
    );
    this.elementAddViewDetailControls(elementSel, 15, 15, 0, 30);
  }

  /* Trigger an element to be deleted from the layer - when deleting a room, delete all sensors too */
  elementDelete(elementSel, elementInfo, options) {
    /* Delete child sensors FIRST when room deleted */
    var sensorLayer = this.chart.layers.sensor;

    sensorLayer.items.each(function (sensor) {
      const roomMatch1 =
        sensor.temp_room_eid != null &&
        sensor.temp_room_eid === elementInfo.eid;
      const roomMatch2 =
        sensor.node_id != null &&
        elementInfo.node_id != null &&
        sensor.node_id === elementInfo.node_id;
      //Check if sensor belongs to room
      if (!roomMatch1 && !roomMatch2) {
        //Continue - does not belong to this room
        return true;
      }

      //Make sure the sensor delete doesn't check the room's entry sensor - that would be silly
      var sensorDeleteOptions = {};
      Object.assign(sensorDeleteOptions, options, {
        sensorTriggerUpdateRoomEntrySensor: false,
      });
      sensorLayer.elementDelete(this, sensor, sensorDeleteOptions);
    });

    //Normal genericLayer delete
    return super.elementDelete(elementSel, elementInfo, options);
  }

  renderElement(elementImageSel, elementInfo) {
    var alphaRooms = 1.0;
    var roomScale = this.scale;
    /* var body_color_rooms = this.chart.constants.room_marker_details.body_color; */
    /* var stroke_color_rooms = this.chart.constants.room_marker_details.line_color; */
    var text_color = this.chart.constants.room_marker_details.text_color;
    if (elementInfo.is_uninstalled) {
      text_color =
        this.chart.constants.room_marker_details.text_color_uninstalled;
    }

    var text_stroke_color =
      this.chart.constants.room_marker_details.text_stroke_color;

    //Draw label
    const roomNumber = this.chart.getRoomNumberFromString(
      elementInfo.name || "UNKNOWN"
    );
    this.ElementArtwork.drawText(
      elementImageSel,
      roomNumber,
      text_color,
      alphaRooms,
      roomScale,
      20,
      0.2,
      text_stroke_color,
      800
    );

    elementImageSel.append("svg:title").text(function () {
      return elementInfo.name;
    });
  }

  /* eslint-disable no-unused-vars */
  elementShouldAllowPositionalEditor(elementSel, elementInfo) {
    //Turn on and off the positional editor
    return true;
  }

  elementClicked(elementSel, elementInfo) {
    //If the main modal already open, ignore subsequent clicks - prevent double opens
    if (
      this.isModalOpen(
        ".roomPrimaryModal." + this.getModalTagClass(elementInfo)
      )
    ) {
      return;
    }

    this.elementDetailShow(elementSel, elementInfo);
  }

  elementDetailShow(elementSel, elementInfo) {
    var _this = this;
    //Create Edit Modal
    var modalEditView = _this.chart
      .d3Select(":root body")
      .append("div")
      .attr("id", "roomViewModal-" + elementInfo.eid)
      .attr(
        "class",
        "modal fade retain overlay roomPrimaryModal " +
          _this.getModalTagClass(elementInfo)
      )
      .attr("role", "dialog");
    var modalContent = modalEditView
      .append("div")
      .attr("class", "modal-dialog modal-lg")
      .append("div")
      .attr("class", "modal-content");
    var modalHeader = modalContent.append("div").attr("class", "modal-header");
    var closeButton = modalHeader
      .append("button")
      .attr("class", "close")
      .attr("data-dismiss", "modal")
      .html("&times;");
    var modalTitle = modalHeader
      .append("h4")
      .attr("class", "modal-title")
      .html(_this.displayName + " Details");
    var modalBody = modalContent.append("div").attr("class", "modal-body");
    var modalFooter = modalContent
      .append("div")
      .attr("class", "modal-footer clearfix");
    var leftButtons = modalFooter
      .append("div")
      .attr("class", "pull-left no-padding-left text-left");
    var rightButtons = modalFooter
      .append("div")
      .attr("class", "pull-right no-padding-right text-right");

    var uninstallButton = null;
    if (!elementInfo.locked) {
      uninstallButton = leftButtons
        .append("button")
        .attr("type", "button")
        .attr("class", "btn btn-danger")
        .html(elementInfo.is_uninstalled ? "Delete" : "Uninstall");
    }

    if (elementInfo._actionButton) {
      if (!elementInfo.locked) {
        leftButtons.append("span").attr("class", "minimal-padding-left");
      }
      $(leftButtons.node()).append($(elementInfo._actionButton));
    }

    if (!elementInfo.locked || elementInfo._actionButton) {
      leftButtons.append("span").attr("class", "minimal-padding-sides");
    }

    var resetButton = null;
    if (
      !elementInfo.locked &&
      elementInfo.id != null &&
      !_this.elementInfoMatchesOriginal(elementInfo)
    ) {
      resetButton = rightButtons
        .append("button")
        .attr("type", "button")
        .attr("class", "btn btn-danger")
        .html('<i class="fa fa-refresh"/>');
    }

    var cancelButton = rightButtons
      .append("button")
      .attr("type", "button")
      .attr("class", "btn btn-default")
      .attr("data-dismiss", "modal")
      .html(elementInfo.locked ? "Dismiss" : "Cancel");
    var saveButton = null;
    if (!elementInfo.locked) {
      saveButton = rightButtons
        .append("button")
        .attr("type", "button")
        .attr("class", "btn btn-primary")
        .html("Update");
    }

    //View input text fields
    var bodyRow = modalBody.append("div").attr("class", "row");
    var bodyRow2 = modalBody.append("div").attr("class", "row");
    var bodyRow3 = modalBody.append("div").attr("class", "row");
    var nameFieldSet = bodyRow
      .append("fieldset")
      .attr("class", "form-group col-md-8 col-xs-6");
    var nameTextLabel = nameFieldSet
      .append("label")
      .attr("for", "name")
      .html("Name:");
    var nameTextField = nameFieldSet
      .append("input")
      .attr("type", "text")
      .attr("class", "form-control elementDetailInputField")
      .attr("name", "name")
      .property("value", elementInfo.name || null);
    var angleFieldSet = bodyRow
      .append("fieldset")
      .attr("class", "form-group col-md-2 col-xs-3");
    var angleTextLabel = angleFieldSet
      .append("label")
      .attr("for", "angle")
      .html("Rotationº:");
    var angleTextField = angleFieldSet
      .append("input")
      .attr("type", "number")
      .attr("step", ".1")
      .attr("class", "form-control elementDetailInputField")
      .attr("name", "angle")
      .property("value", elementInfo.angle);
    var idFieldSet = bodyRow
      .append("fieldset")
      .attr("class", "form-group col-md-2 col-xs-3");
    var idTextLabel = idFieldSet
      .append("label")
      .attr("for", "roomConfigId")
      .html("ID:");
    var idTextField = idFieldSet
      .append("input")
      .attr("type", "text")
      .attr("class", "form-control elementDetailInputField")
      .attr("readonly", true)
      .attr("name", "roomConfigId")
      .property("value", elementInfo.id || null);

    var roomModeModeConfigFieldset = bodyRow
      .append("fieldset")
      .attr("class", "form-group col-sm-6 col-xs-12");
    roomModeModeConfigFieldset
      .append("label")
      .attr("for", "roomModeModeConfigSelect")
      .html("Normal " + _this.displayName + " Mode's Mode Config:");
    var roomModeModeConfigSelect = roomModeModeConfigFieldset
      .append("select")
      .attr(
        "class",
        "form-control elementDetailInputField " +
          (elementInfo.locked ? "" : "chosen-select")
      )
      .attr("name", "roomModeModeConfigSelect")
      .attr("id", elementInfo.eid + "_roomModeModeConfigSelect");
    roomModeModeConfigSelect
      .append("option")
      .property("value", null)
      .text(null);

    var ignorePairTimingGroupFieldset = bodyRow
      .append("fieldset")
      .attr("class", "form-group col-sm-6 col-xs-12");
    ignorePairTimingGroupFieldset
      .append("label")
      .attr("for", "ignorePairTimingGroupSelect")
      .html(
        "Override " +
          _this.chart.getLayerDisplayName("ignorePairTimingGroup") +
          ":"
      );
    var ignorePairTimingGroupSelect = ignorePairTimingGroupFieldset
      .append("select")
      .attr(
        "class",
        "form-control elementDetailInputField " +
          (elementInfo.locked ? "" : "chosen-select")
      )
      .attr("name", "ignorePairTimingGroupSelect")
      .attr("id", elementInfo.eid + "_ignorePairTimingGroupSelect");
    var defaultIgnorePairTimingGroup = ignorePairTimingGroupSelect
      .append("option")
      .property("value", null)
      .text(
        "[NORMAL] None, Use " +
          _this.chart.getLayerDisplayName("nodeMap") +
          "'s Default"
      );
    if (elementInfo.normal_modeSensorConfig_id == null) {
      defaultIgnorePairTimingGroup.attr("selected", "selected");
    }

    var inRoomSensors = _this.getAllSensorsInThisRoom(elementInfo, false, true);
    var inRoomRouters = _this.getAllRoutersInThisRoom(elementInfo, true);
    var entrySensors = inRoomSensors.filter(function (sensor) {
      return _this.chart.map_data.sensorRolesInfo[sensor.sensorRole_id]
        .is_entry;
    });
    var nonProxSensors = inRoomSensors.filter(function (sensor) {
      return !_this.chart.map_data.sensorRolesInfo[sensor.sensorRole_id]
        .is_entry;
    });
    var proxTypeIds = [];
    entrySensors.each(function (sensor) {
      if (sensor.proxType_id === undefined || sensor.proxType_id == null) {
        return true; //continue
      }
      if ($.inArray(sensor.proxType_id, proxTypeIds) >= 0) {
        return true; //continue
      }
      proxTypeIds.push(sensor.proxType_id);
    });

    $.each(
      _this.chart.map_data.modeSensorConfigs,
      function (dummyIndex2, modeConfigInfo) {
        if ($.inArray(modeConfigInfo.proxType_id, proxTypeIds) < 0) {
          return true; //continue
        }

        var newOpt = roomModeModeConfigSelect
          .append("option")
          .property("value", _this.chart.filterInt(modeConfigInfo.id))
          .text(
            modeConfigInfo.display_name +
              " {" +
              modeConfigInfo.proxType_info.short +
              "}"
          );

        if (modeConfigInfo.id === elementInfo.normal_modeSensorConfig_id) {
          newOpt.attr("selected", "selected");
        }
      }
    );

    $.each(
      _this.chart.map_data.timingGroupOptions,
      function (timingGroupId, timingGroupName) {
        var newOpt = ignorePairTimingGroupSelect
          .append("option")
          .property("value", _this.chart.filterInt(timingGroupId))
          .text("[OVERRIDE-RARE] " + timingGroupName);

        if (timingGroupId === elementInfo.ignore_pair_timing_group_id) {
          newOpt.attr("selected", "selected");
        }
      }
    );

    var otherRoomModesFieldset = bodyRow
      .append("fieldset")
      .attr("class", "form-group col-xs-12");
    var otherRoomModesSelect = null;
    if (elementInfo.locked) {
      otherRoomModesFieldset
        .append("label")
        .attr("for", "otherRoomModesDisplayTextfield")
        .html("Other " + _this.displayName + " Modes:");
      var otherRoomModesDisplayText = "";
      $.each(
        Object.values(_this.chart.map_data.roomModeTypesInfo).sort(
          function (a, b) {
            return a.sort_order_number - b.sort_order_number;
          }
        ),
        function (randomKeyBecauseSort, roomModeTypeInfo) {
          const idInt = _this.chart.filterInt(roomModeTypeInfo.id);
          if (idInt === _this.chart.map_data.normalRoomModeTypeId) {
            return true; //continue
          }

          if ($.inArray(idInt, elementInfo.other_roomModeType_ids || []) >= 0) {
            if (otherRoomModesDisplayText.length > 0) {
              otherRoomModesDisplayText += ", ";
            }
            otherRoomModesDisplayText += roomModeTypeInfo.name;
          }
        }
      );
      otherRoomModesFieldset
        .append("input")
        .attr("type", "text")
        .attr("class", "form-control elementDetailInputField")
        .attr("name", "otherRoomModesDisplayTextfield")
        .attr("id", elementInfo.eid + "_otherRoomModesDisplayTextfield")
        .property("value", otherRoomModesDisplayText);
    } else {
      otherRoomModesFieldset
        .append("label")
        .attr("for", "otherRoomModesSelect")
        .html("Other " + _this.displayName + " Modes:");
      otherRoomModesSelect = otherRoomModesFieldset
        .append("select")
        .attr("class", "form-control chosen-select elementDetailInputField")
        .attr("name", "otherRoomModesSelect")
        .attr("multiple", true)
        .attr("id", elementInfo.eid + "_otherRoomModesSelect");

      $.each(
        Object.values(_this.chart.map_data.roomModeTypesInfo).sort(
          function (a, b) {
            return a.sort_order_number - b.sort_order_number;
          }
        ),
        function (randomKeyBecauseSort, roomModeTypeInfo) {
          const idInt = _this.chart.filterInt(roomModeTypeInfo.id);
          if (idInt === _this.chart.map_data.normalRoomModeTypeId) {
            return true; //continue
          }

          var newOpt = otherRoomModesSelect
            .append("option")
            .property("value", idInt)
            .text(roomModeTypeInfo.name);

          if ($.inArray(idInt, elementInfo.other_roomModeType_ids || []) >= 0) {
            newOpt.attr("selected", "selected");
          }
        }
      );
    }

    var commentDetailFieldSet = bodyRow
      .append("fieldset")
      .attr("class", "form-group col-sm-12");
    var commentDetailTextFieldLabel = commentDetailFieldSet
      .append("label")
      .attr("for", "comment-description")
      .html("Comments:");
    var commentDetailTextField = commentDetailFieldSet
      .append("textarea")
      .attr("class", "form-control resizeable-vertical elementDetailInputField")
      .attr("name", "comment-description")
      .attr("rows", 3)
      .property("value", elementInfo.comment_text);

    var currentEntrySensorsSect = bodyRow2
      .append("div")
      .attr("class", "col-md-6 col-xs-12");
    currentEntrySensorsSect
      .append("label")
      .attr("class", "col-xs-12 no-padding-left")
      .html(
        "In-Room Entry " +
          (entrySensors.size() !== 1 ? "Sensors" : "Sensor") +
          ":"
      );
    var currentEntrySensorsSectUl = currentEntrySensorsSect
      .append("ul")
      .attr("class", "col-xs-12");
    // Use entry sensors and prox type ids from above
    entrySensors.each(function (sensor) {
      currentEntrySensorsSectUl
        .append("li")
        .attr("class", "add-padding-sides")
        .html(
          sensor.name +
            "<i> - (" +
            _this.chart.map_data.proxTypesInfo[sensor.proxType_id].name +
            " Sensor)</i>"
        );
    });
    if (entrySensors.size() === 0) {
      currentEntrySensorsSectUl
        .append("li")
        .attr("class", "add-padding-sides")
        .append("i")
        .attr("class", "text-danger")
        .html("*There are NO entry sensors in this room!");
    }
    if (proxTypeIds.length > 1) {
      currentEntrySensorsSectUl
        .append("li")
        .attr("class", "add-padding-sides")
        .append("i")
        .attr("class", "text-danger")
        .html(
          "*There are " +
            proxTypeIds.length +
            " DIFFERENT types of entry sensors in this room!"
        );
    }

    var currentNonProxSensorsSect = bodyRow2
      .append("div")
      .attr("class", "col-md-6 col-xs-12");
    currentNonProxSensorsSect
      .append("label")
      .attr("class", "col-xs-12 no-padding-left")
      .html(
        "In-Room Non-Prox " +
          (nonProxSensors.size() !== 1 ? "Sensors" : "Sensor") +
          ":"
      );
    var currentNonProxSensorsSectUl = currentNonProxSensorsSect
      .append("ul")
      .attr("class", "col-xs-12");
    // Use NonProx sensors and prox type ids from above
    nonProxSensors.each(function (sensor) {
      currentNonProxSensorsSectUl
        .append("li")
        .attr("class", "add-padding-sides")
        .html(sensor.name);
    });
    if (nonProxSensors.size() === 0) {
      currentNonProxSensorsSectUl
        .append("li")
        .attr("class", "add-padding-sides")
        .append("i")
        .attr("class", "text-danger")
        .html("*There are NO Non-Prox sensors in this room!");
    }

    var currentInRoomRoutersSect = bodyRow3
      .append("div")
      .attr("class", "col-md-6 col-xs-12");
    currentInRoomRoutersSect
      .append("label")
      .attr("class", "col-xs-12 no-padding-left")
      .html(
        "In-Room " +
          _this.chart.getLayerDisplayName("router") +
          (entrySensors.size() !== 1 ? "s" : "") +
          ":"
      );
    var currentInRoomRoutersSectUl = currentInRoomRoutersSect
      .append("ul")
      .attr("class", "col-xs-12");
    // Use NonProx sensors and prox type ids from above
    inRoomRouters.each(function (router) {
      currentInRoomRoutersSectUl
        .append("li")
        .attr("class", "add-padding-sides")
        .html(router.name);
    });
    if (inRoomRouters.size() === 0) {
      currentInRoomRoutersSectUl
        .append("li")
        .attr("class", "add-padding-sides")
        .append("i")
        .attr("class", "text-danger")
        .html(
          "*There are NO " +
            _this.chart.getLayerDisplayName("router") +
            "s in this room!"
        );
    }

    if (elementInfo.readonly_attributes.problemsList) {
      bodyRow
        .append("div")
        .attr("class", "col-xs-12 no-padding-bottom")
        .append("hr")
        .attr("class", "minus-padding-sides minimal-padding-bottom");
      var currentProblemsSect = bodyRow
        .append("div")
        .attr("class", "col-xs-12");
      currentProblemsSect
        .append("label")
        .attr("class", "col-xs-12 no-padding-left")
        .html(_this.displayName + " Problems:");
      var currentProblemsSectUl = currentProblemsSect
        .append("ul")
        .attr("class", "col-xs-12");
      $.each(
        elementInfo.readonly_attributes.problemsList,
        function (index, problemInfo) {
          currentProblemsSectUl
            .append("li")
            .attr("class", "add-padding-sides")
            .html(
              '<span class="text-underline">' +
                problemInfo.name +
                "</span>: <i>" +
                problemInfo.action +
                "</i>"
            );
        }
      );
      if (elementInfo.readonly_attributes.problemsList.length === 0) {
        currentProblemsSectUl
          .append("li")
          .attr("class", "add-padding-sides")
          .html("<i> None! </i>");
      }
    }

    var showRenamedRoomSensorsDialog = function (
      oldName,
      newName,
      oldRoomNumber,
      newRoomNumber,
      roomEid,
      roomNodeId
    ) {
      /* Rename room's sensors when room re-named (Doesn't rename hallway sensors!!!) */
      var roomRenameSensorsRoom = [];
      var roomRenameSensorsRoomEids = [];
      var roomUnRenameSensors = [];
      var roomUnRenameSensorsEids = [];
      var roomRenameSensorsHall = [];
      var roomRenameSensorsHallEids = [];

      //Regex to check for "Room xxx" in a sensor's name
      const roomNameContainsReg = new RegExp(`(\\b|^)${oldName}(\\b|$)`, "i");

      //Regex to check for room's number in a sensor name
      const roomNameContainsNumberReg = new RegExp(
        `(\\b|^)${oldRoomNumber}(\\b|$)`,
        "i"
      );
      _this.getAllSensorLayerSensorsAlphabetical().each(function (sensor) {
        const roomMatch1 =
          sensor.temp_room_eid != null && sensor.temp_room_eid === roomEid;
        const roomMatch2 =
          sensor.node_id != null &&
          roomNodeId != null &&
          sensor.node_id === roomNodeId;
        const hallMatchName =
          _this.chart.map_data.sensorRolesInfo[sensor.sensorRole_id]
            .is_hallway === true && roomNameContainsNumberReg.test(sensor.name);
        //Check if sensor belongs to room
        if (!roomMatch1 && !roomMatch2 && !hallMatchName) {
          //Continue - does not belong to this room, not could it be a hallway sensor for this room
          return true;
        }

        const nameChangeString =
          sensor.name +
          "<i> => " +
          sensor.name
            .replace(oldName, newName)
            .replace(oldRoomNumber, newRoomNumber) +
          "</i>";
        //Check if only hall match
        if (!roomMatch1 && !roomMatch2) {
          roomRenameSensorsHall.push(nameChangeString);
          roomRenameSensorsHallEids.push(sensor.eid);
          //Continue - does not belong to this room

          return true;
        } else if (
          roomNameContainsReg.test(sensor.name) ||
          roomNameContainsNumberReg.test(sensor.name)
        ) {
          //Check if sensor name contains room name
          roomRenameSensorsRoom.push(nameChangeString);
          roomRenameSensorsRoomEids.push(sensor.eid);
        } else {
          roomUnRenameSensors.push(nameChangeString);
          roomUnRenameSensorsEids.push(sensor.eid);
        }
      });

      //Display warning message if room renamed
      const sensorsInRoomString =
        "<br/><br/><b>In-Room Sensors:</b><br/>" +
        (roomRenameSensorsRoom.length > 0
          ? "<span class='text-primary'> - " +
            roomRenameSensorsRoom.join("<br/> - ") +
            "</span>"
          : "<span class='text-danger'> - None Found!</span>");
      const sensorsInHallString =
        "<br/><br/><b>Hallway Sensors:</b><br/>" +
        (roomRenameSensorsHall.length > 0
          ? "<span class='text-primary'> - " +
            roomRenameSensorsHall.join("<br/> - ") +
            "</span>"
          : "<span class='text-danger'> - None Found!</span>");
      const sensorsUndoableString =
        roomUnRenameSensors.length > 0
          ? "<br/><br/><br/><span class='text-danger'>" +
            roomUnRenameSensors.length +
            " in-room sensor" +
            (roomUnRenameSensors.length !== 1 ? "s are" : " is") +
            " not able to be updated as" +
            (roomUnRenameSensors.length !== 1 ? " they do" : " it does") +
            " not have the room's name in " +
            (roomUnRenameSensors.length !== 1 ? " their names" : " it's name") +
            "</span><br/><b>In-Room Sensors NOT Renameable:</b><br/><span class='text-danger'> - " +
            roomUnRenameSensors.join("<br/> - ") +
            "</span>"
          : "";

      var renameAllButton = null;
      if (
        roomRenameSensorsHall.length > 0 &&
        roomRenameSensorsRoom.length > 0
      ) {
        renameAllButton = _this.chart
          .d3Create("button")
          .attr("type", "button")
          .attr("class", "btn btn-success")
          .html("Rename " + _this.displayName + " & Hall Sensors");
      }
      var renameInRoomButton = null;
      if (roomRenameSensorsRoom.length > 0) {
        renameInRoomButton = _this.chart
          .d3Create("button")
          .attr("type", "button")
          .attr("class", "btn btn-warning")
          .html("Rename ONLY In-Rooms");
      }

      var warningDialog = _this.chart.bootstrapAlert(
        "Rename " + _this.displayName + "'s Sensors?",
        "<i>" +
          oldName +
          " has been renamed to " +
          newName +
          ". Would you like to rename related sensors too?</i>" +
          sensorsInRoomString +
          sensorsInHallString +
          sensorsUndoableString,
        renameAllButton,
        renameInRoomButton
      );
      $(warningDialog.node()).addClass(_this.getModalTagClass(elementInfo));

      //If the user clicks "rename _ sensors", we need to actually do that
      if (renameInRoomButton) {
        //Capture Modal Button Events for Fix All Hallways
        $(renameInRoomButton.node()).on("click", function () {
          renameSensors(roomRenameSensorsRoomEids);
          $(warningDialog.node()).modal("hide");
        });
      }
      if (renameAllButton) {
        //Capture Modal Button Events for Fix All Hallways
        $(renameAllButton.node()).on("click", function () {
          renameSensors(
            $.merge(roomRenameSensorsRoomEids, roomRenameSensorsHallEids)
          );
          $(warningDialog.node()).modal("hide");
        });
      }
      var renameSensors = function (eids) {
        _this.chart.layers.sensor.items.each(function (sensor) {
          if (
            eids.some(function (item) {
              return item === sensor.eid;
            })
          ) {
            sensor.name = sensor.name
              .replace(oldName, newName)
              .replace(oldRoomNumber, newRoomNumber);
            _this.chart.d3Select(this).dispatch("rerender");
          }
        });
      };
    };

    //Setup chosen selects for room mode mode sensor config selectors (but also any others would be setup by this func) - relies on core scripts being loaded independently
    window.CoreScripts.setupChosenSelects();

    //Lock all inputs if element locked
    if (elementInfo.locked) {
      var $inputFields = $(modalContent.node()).find(
        ".elementDetailInputField"
      );
      if ($inputFields.length > 0) {
        $inputFields.prop("readonly", true);
        var $selectInputFields = $inputFields.filter("select");
        if ($selectInputFields.length > 0) {
          $selectInputFields.prop("disabled", true);
        }
        var $btnFields = $inputFields.filter(".btn");
        if ($btnFields.length > 0) {
          $btnFields.addClass("btn-disabled pointer-no-interaction-force");
        }
      }
    }

    //Capture Modal Button Events
    if (saveButton != null) {
      $(saveButton.node()).on("click", function () {
        //Validate name
        const newName = _this.chart.trimStringAndReplaceEmptyWithNull(
          nameTextField.node().value || null
        );
        const nameValidation = _this.chart.validateDeviceName(
          "room",
          newName,
          elementInfo.eid
        );
        if (nameValidation !== true) {
          var warningDialogName = _this.chart.bootstrapAlert(
            "Cannot Update",
            nameValidation
          );
          $(warningDialogName.node()).addClass(
            _this.getModalTagClass(elementInfo)
          );

          return false;
        }
        const oldName = elementInfo.name;
        elementInfo.name = newName;
        elementInfo.angle = (angleTextField.node().value || 0) % 360;
        elementInfo.comment_text =
          _this.chart.trimStringAndReplaceEmptyWithNull(
            commentDetailTextField.node().value || null
          );
        elementInfo.normal_modeSensorConfig_id = _this.chart.filterInt(
          roomModeModeConfigSelect.node().value
        );
        elementInfo.ignore_pair_timing_group_id = _this.chart.filterInt(
          ignorePairTimingGroupSelect.node().value
        );
        if (otherRoomModesSelect != null) {
          elementInfo.other_roomModeType_ids = $.map(
            $(otherRoomModesSelect.node()).val(),
            (subValue) => _this.chart.filterInt(subValue)
          ).sort();
        }

        // Update element rendering and render dirty if it is dirty
        elementSel.dispatch("rerender");

        $(modalEditView.node()).modal("hide");

        //If room number changed, rename sensors
        const oldRoomNumber = _this.chart.getRoomNumberFromString(oldName);
        const newRoomNumber = _this.chart.getRoomNumberFromString(
          elementInfo.name
        );
        if (oldRoomNumber !== newRoomNumber) {
          showRenamedRoomSensorsDialog(
            oldName,
            elementInfo.name,
            oldRoomNumber,
            newRoomNumber,
            elementInfo.eid,
            elementInfo.node_id
          );
        }
      });
    }

    if (resetButton != null) {
      $(resetButton.node()).on("click", function () {
        var resetConfirmButton = _this.chart
          .d3Create("button")
          .attr("type", "button")
          .attr("class", "btn btn-danger")
          .html("Reset Attributes to Original");
        var resetDialog = _this.chart.bootstrapAlert(
          "Reset " + _this.displayName + " to Original?",
          "<span class='text-danger'>Are you really sure you want to reset all of this room's attributes to their original values? This includes location on the map and cannot be undone.</span>",
          resetConfirmButton
        );
        $(resetConfirmButton.node()).on("click", function () {
          elementSel.dispatch("resetToOriginal");
          $(resetDialog.node()).modal("hide");
          $(modalEditView.node()).modal("hide");
        });
      });
    }
    // if (removeButton != null) {
    //     $(removeButton.node()).on("click", function() {
    //         _this.elementRemove(elementSel, elementInfo);
    //         $(modalEditView.node()).modal('hide');
    //     });
    // }
    // if (deleteButton != null) {
    //     $(deleteButton.node()).on("click", function () {
    //         _this.elementDelete(elementSel, elementInfo);
    //         $(modalEditView.node()).modal('hide');
    //     });
    // }

    if (uninstallButton != null) {
      $(uninstallButton.node()).on("click", function () {
        $(modalEditView.node()).modal("hide");
        _this.promptUninstallRoom(elementSel, elementInfo);
      });
    }

    //Destroy modal when hidden
    $(modalEditView.node()).on("hidden.bs.modal", function () {
      $(this).remove();
    });
    //Handle Modal Show
    $(modalEditView.node()).modal("show");
  }

  promptUninstallRoom(elementSel, elementInfo) {
    var _this = this;

    var roomSensorsInRoom = [];
    var roomSensorsInRoomEids = [];
    var roomRoutersInRoom = [];
    var roomRoutersInRoomEids = [];

    //Regex to check for room's number in a sensor name
    _this.getAllSensorLayerSensorsAlphabetical().each(function (sensor) {
      const roomMatch1 =
        sensor.temp_room_eid != null &&
        sensor.temp_room_eid === elementInfo.eid;
      const roomMatch2 =
        sensor.node_id != null &&
        elementInfo.node_id != null &&
        sensor.node_id === elementInfo.node_id;

      //Check if sensor belongs to room
      if (!roomMatch1 && !roomMatch2) {
        //Continue - does not belong to this room
        return true;
      }

      roomSensorsInRoom.push(sensor.name);
      roomSensorsInRoomEids.push(sensor.eid);
    });

    _this.getAllRoutersInThisRoom(elementInfo, true).each(function (router) {
      roomRoutersInRoom.push(router.name);
      roomRoutersInRoomEids.push(router.eid);
    });

    var devicesInRoomString =
      "<br/><br/><b>In-Room Sensors:</b><br/>" +
      (roomSensorsInRoom.length > 0
        ? "<span class='text-primary'> - " +
          roomSensorsInRoom.join("<br/> - ") +
          "</span>"
        : "<span class='text-danger'>None Found!</span>");
    devicesInRoomString +=
      "<br/><br/><b>In-Room " +
      _this.chart.getLayerDisplayName("router") +
      "s:</b><br/>" +
      (roomRoutersInRoom.length > 0
        ? "<span class='text-primary'> - " +
          roomRoutersInRoom.join("<br/> - ") +
          "</span>"
        : "<span class='text-danger'>None Found!</span>");

    var messageTitle = "Uninstall " + _this.displayName + "?";
    var messageText =
      "<i>Would you like to Uninstall (Delete) the Room's In-Room Sensors and " +
      _this.chart.getLayerDisplayName("router") +
      "? Please be sure you have each of these devices in your hand before continuing!</i>" +
      devicesInRoomString;

    const allDevicesAreUninstalled =
      roomSensorsInRoom.length === 0 && roomRoutersInRoom.length === 0;

    var uninstallInRoomButton = null;
    if (!allDevicesAreUninstalled && !elementInfo.is_uninstalled) {
      uninstallInRoomButton = _this.chart
        .d3Create("button")
        .attr("type", "button")
        .attr("class", "btn btn-warning")
        .html("Uninstall In-Room Devices & Room");
    }

    var deleteRoomButton = null;
    var uninstallRoomButton = null;
    if (allDevicesAreUninstalled || elementInfo.is_uninstalled) {
      deleteRoomButton = _this.chart
        .d3Create("button")
        .attr("type", "button")
        .attr("class", "btn btn-danger")
        .html("Fully Delete Room");
      messageTitle = "Delete " + _this.displayName + "?";
      messageText =
        "There are no devices installed in this Room. Would you like to fully delete this Room? Doing so will remove access to the Room's data via the App! Please proceed with caution.";
      if (!elementInfo.is_uninstalled) {
        uninstallRoomButton = _this.chart
          .d3Create("button")
          .attr("type", "button")
          .attr("class", "btn btn-warning")
          .html("Just Uninstall Room");
      }
    }

    var warningDialog = _this.chart.bootstrapAlert(
      messageTitle,
      messageText,
      deleteRoomButton,
      uninstallRoomButton ?? uninstallInRoomButton
    );
    $(warningDialog.node()).addClass(_this.getModalTagClass(elementInfo));

    //If the user clicks "rename _ sensors", we need to actually do that
    if (uninstallInRoomButton) {
      //Capture Modal Button Events for Fix All Hallways
      $(uninstallInRoomButton.node()).on("click", function () {
        uninstallSensorsAndRoutersAndRoom(
          roomSensorsInRoomEids,
          roomRoutersInRoomEids
        );
        $(warningDialog.node()).modal("hide");
      });
    }
    if (uninstallRoomButton) {
      //Capture Modal Button Events for Fix All Hallways
      $(uninstallRoomButton.node()).on("click", function () {
        uninstallSensorsAndRoutersAndRoom([], []);
        $(warningDialog.node()).modal("hide");
      });
    }
    if (deleteRoomButton) {
      //Capture Modal Button Events for Fix All Hallways
      $(deleteRoomButton.node()).on("click", function () {
        _this.elementDelete(elementSel, elementInfo);
        $(warningDialog.node()).modal("hide");
      });
    }
    var uninstallSensorsAndRoutersAndRoom = function (sensorEids, routerEids) {
      //Make sure the sensor delete doesn't check the room's entry sensor - that would be silly
      var sensorLayer = _this.chart.layers.sensor;
      var sensorDeleteOptions = {};
      Object.assign(
        sensorDeleteOptions,
        {},
        { sensorTriggerUpdateRoomEntrySensor: false }
      );

      _this.chart.layers.sensor.items.each(function (sensor) {
        if (
          sensorEids.some(function (item) {
            return item === sensor.eid;
          })
        ) {
          sensorLayer.elementDelete(this, sensor, sensorDeleteOptions);
        }
      });

      var routerLayer = _this.chart.layers.router;
      _this.chart.layers.router.items.each(function (router) {
        if (
          routerEids.some(function (item) {
            return item === router.eid;
          })
        ) {
          // Mark the router released
          router.private_attributes.shouldRelease = true;
          // Remove it from map
          routerLayer.elementRemove(this, router);
        }
      });

      elementInfo.is_uninstalled = true;
      elementSel.dispatch("rerender");
    };
  }

  getAllSensorLayerSensorsAlphabetical() {
    return this.chart.layers.sensor.items.sort(function (a, b) {
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });
  }

  getAllSensorsInThisRoom(elementInfo, onlyEntry, sortAlphabetically) {
    var _this = this;
    var toReturn = this.chart.layers.sensor.items.filter(function (sensor) {
      const roomMatch1 =
        sensor.temp_room_eid != null &&
        sensor.temp_room_eid === elementInfo.eid;
      const roomMatch2 =
        sensor.node_id != null &&
        elementInfo.node_id != null &&
        sensor.node_id === elementInfo.node_id;

      return (
        (roomMatch1 || roomMatch2) &&
        (!onlyEntry ||
          _this.chart.map_data.sensorRolesInfo[sensor.sensorRole_id].is_entry)
      );
    });

    if (sortAlphabetically) {
      toReturn = toReturn.sort(function (a, b) {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });
    }

    return toReturn;
  }

  getAllRoutersInThisRoom(elementInfo, sortAlphabetically) {
    var toReturn = this.chart.layers.router.items.filter(function (router) {
      const roomMatch1 =
        router.temp_room_eid != null &&
        router.temp_room_eid === elementInfo.eid;
      const roomMatch2 =
        router.node_id != null &&
        elementInfo.node_id != null &&
        router.node_id === elementInfo.node_id;

      return roomMatch1 || roomMatch2;
    });

    if (sortAlphabetically) {
      toReturn = toReturn.sort(function (a, b) {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });
    }

    return toReturn;
  }

  entrySensorAddedToRoom(roomEid) {
    var _this = this;

    var roomGet = this.getItemByEid(roomEid);
    var entrySensors = _this.getAllSensorsInThisRoom(roomGet.info, true, false);
    var proxTypeIds = [];
    entrySensors.each(function (sensor) {
      if (sensor.proxType_id === undefined || sensor.proxType_id == null) {
        return true; //continue
      }
      if ($.inArray(sensor.proxType_id, proxTypeIds) >= 0) {
        return true; //continue
      }
      proxTypeIds.push(sensor.proxType_id);
    });

    var countFoundMatching = 0;
    if (proxTypeIds.length > 0) {
      $.each(
        _this.chart.map_data.modeSensorConfigs,
        function (dummyIndex, modeConfigInfo) {
          //Skip all non-prox mode configs
          if (modeConfigInfo.proxType_info.sensorRole_id == null) {
            return true;
          }

          if ($.inArray(modeConfigInfo.proxType_id, proxTypeIds) < 0) {
            return true;
          }

          roomGet.info.normal_modeSensorConfig_id = _this.chart.filterInt(
            modeConfigInfo.id
          );
          countFoundMatching += 1;
        }
      );
    }

    if (
      countFoundMatching === 0 &&
      roomGet.info.normal_modeSensorConfig_id !== null
    ) {
      roomGet.info.normal_modeSensorConfig_id = null;
    }

    roomGet.sel.dispatch("rerender");

    //Alert us if we weren't able to automatically correct the mode configs
    if (proxTypeIds.length > 1) {
      var modeConfigWarningDialog = this.chart.bootstrapAlert(
        "Incorrect Configuration - " + roomGet.info.name,
        "This " +
          _this.displayName +
          " has " +
          entrySensors.size() +
          " entry sensors of " +
          proxTypeIds.length +
          " different types. Only one type of entry sensor may be used at a time!!! This must be resolved immediately."
      );
      $(modeConfigWarningDialog.node()).addClass(
        _this.getModalTagClass(roomGet.info)
      );
    } else if (entrySensors.size() === 0) {
      var entrySensorWarningDialog = this.chart.bootstrapAlert(
        "No Entry Sensor - " + roomGet.info.name,
        "This " +
          _this.displayName +
          " has no entry sensors. Please add an entry sensor to " +
          roomGet.info.name +
          "!"
      );
      $(entrySensorWarningDialog.node()).addClass(
        _this.getModalTagClass(roomGet.info)
      );
    } else if (countFoundMatching > 1) {
      var editModeConfigsButton = _this.chart
        .d3Create("button")
        .attr("type", "button")
        .attr("class", "btn btn-info")
        .html(
          "Edit " + _this.displayName + "'s Normal Room Mode's Mode Config"
        );
      var warnAmbiguousDialog = this.chart.bootstrapAlert(
        roomGet.info.name + " - Ambiguous Configuration",
        "This " +
          _this.displayName +
          " had multiple possible Mode Configs for the given entry sensor. We tried to guess, but you probably should check the Room's Normal Room Mode's Mode Config for the " +
          _this.displayName +
          " to ensure it's correct.",
        editModeConfigsButton
      );
      $(warnAmbiguousDialog.node()).addClass(
        _this.getModalTagClass(roomGet.info)
      );
      $(editModeConfigsButton.node()).on("click", function () {
        _this.elementDetailShow(roomGet.sel, roomGet.info);
        $(warnAmbiguousDialog.node()).modal("hide");
      });
    }
  }
}
