import { defer } from "react-router-dom";
import { get } from "../../api/laravelApi";
import {
  roomsOverviewParamsEndpoint,
  roomsOverviewSummaryEndpoint,
  roomsOverviewTableEndpoint,
} from "../../constants";
import { getSearchParamsDefer } from "../loaderHelper";

export const RoomsOverviewLoader = async (request) => {
  const url = new URL(request.url);
  const searchParams = new URLSearchParams(url.search);

  const allParams = getSearchParamsDefer(searchParams);

  const getAllFiltersJson = await get(roomsOverviewParamsEndpoint);
  const allFiltersJson = getAllFiltersJson.params;
  const transformedFiltersJson = Object.values(allFiltersJson).map((value) => {
    const filterValue = value as {
      key: string;
      searchParamName: string;
      type: string;
    };
    return {
      key: filterValue.key,
      label: filterValue.searchParamName,
      type: filterValue.type,
    };
  });

  return get(roomsOverviewParamsEndpoint, allParams, transformedFiltersJson)
    .then((resp) => {
      return [allParams, resp];
    })
    .then(([_params, resp]) => {
      const summary = get(
        roomsOverviewSummaryEndpoint,
        allParams,
        transformedFiltersJson
      );
      const roomsTable = get(
        roomsOverviewTableEndpoint,
        allParams,
        transformedFiltersJson
      );

      return defer({
        summary,
        roomsTable,
        filters: resp,
      });
    });
};
