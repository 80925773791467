import {
  defaultDateRange,
  oneWeekDateRange,
  userAccessEndpoint,
} from "../constants";
import { formatDate } from "../utils";

type BaseUrlParams = {
  startDate?: string | null;
  endDate?: string | null;
  customerIds?: string[] | null;
  facilityIds?: string[] | null;
  roomIds?: string[] | null;
  unitIds?: string[] | null;
  exposureType?: string[] | null;
  shiftSelector?: string | null;
  unitTypeIds?: string[] | null;
  aggregation?: string | null;
};

export interface HandHygieneUrlParams extends BaseUrlParams {
  groupIds?: string[] | null;
  sumOverTimeRange?: boolean | null;
  user_ids_filter?: string | null;
}

export interface AssetTrackingUrlParams extends BaseUrlParams {
  typeIds?: string[] | null;
  modelIds?: string[] | null;
}

export interface NursingInsightsUrlParams extends BaseUrlParams {
  roomIds?: string[] | null;
}

type AnyUrlParams =
  | HandHygieneUrlParams
  | NursingInsightsUrlParams
  | AssetTrackingUrlParams;

function includeDateRangeParams(
  params: AnyUrlParams
): params is { startDate: string; endDate: string } {
  return !!params.startDate && !!params.endDate;
}

export async function get(
  endpoint: string,
  urlParams?: Object,
  formatParams?: { label: any; key: string | number }[]
) {
  let response;

  let searchParams = new URLSearchParams();

  if (!urlParams) {
    if (endpoint.includes("rooms-history")) {
      const defaultParams = {
        date_range_filter: `DD|${formatDate(
          oneWeekDateRange.startDate
        )}|${formatDate(oneWeekDateRange.endDate)}`,
      };
      searchParams = new URLSearchParams(defaultParams);
    } else {
      const defaultParams = {
        date_range_filter: `DD|${formatDate(
          defaultDateRange.startDate
        )}|${formatDate(defaultDateRange.endDate)}`,
      };
      searchParams = new URLSearchParams(defaultParams);
    }
  } else {
    // to do update date range to handle logic like the other filters
    if (includeDateRangeParams(urlParams))
      searchParams.append(
        "date_range_filter",
        `DD|${urlParams.startDate}|${urlParams.endDate}`
      );

    function mergeObjects(
      allParams: { label: any; key: string | number }[],
      urlParams: { [x: string]: any; hasOwnProperty: (arg0: any) => any }
    ) {
      const result = {};

      allParams.forEach((item: { label: any; key: string | number }) => {
        const matchingKey = item.label;
        if (urlParams.hasOwnProperty(matchingKey)) {
          result[item.key] = {
            ...item,
            value: urlParams[matchingKey],
          };
        }
      });

      return result;
    }

    const mergedParams = mergeObjects(formatParams || [], urlParams);

    Object.values(mergedParams).forEach((filter) => {
      const { key, value } = filter as {
        key: string;
        value: string | string[];
      };

      if (Array.isArray(value)) {
        value.forEach((item) => {
          searchParams.append(`${key}[]`, item);
        });
      } else {
        searchParams.append(key, value);
      }
    });
  }

  const url = `${process.env.REACT_APP_AUTH_DOMAIN}/${endpoint}?${searchParams}`;

  response = await fetch(url, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "x-swipesense-platform": "spa",
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.assign(
        process.env.REACT_APP_AUTH_DOMAIN +
          "?redirectAddress=" +
          window.location.href +
          "&_storeSourceAsIntendedUrl=true"
      );
      throw new Response("Expired", { status: 401 });
    } else if (response.status === 403) {
      throw new Response("Forbidden", { status: 403 });
    } else if (response.status === 503) {
      window.location.href = "/maintenance";
    } else {
      throw new Error("Could not fetch data");
    }
  } else {
    return response.json();
  }
}

export async function getSigned(
  uuid: string,
  expires: string | null,
  signature: string
) {
  const urlParams = new URLSearchParams({
    signature,
  });

  if (expires) {
    urlParams.append("expires", expires);
  }

  const domain = process.env.REACT_APP_AUTH_DOMAIN;
  const path = `report/s/${uuid}`;
  const url = `${domain}/${path}?${urlParams.toString()}`;

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "x-swipesense-platform": "spa",
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.assign(
        process.env.REACT_APP_AUTH_DOMAIN +
          "?redirectAddress=" +
          window.location.href +
          "&_storeSourceAsIntendedUrl=true"
      );
      throw new Response("Expired", { status: 401 });
    } else if (response.status === 403) {
      throw new Response("Forbidden", { status: 403 });
    } else if (response.status === 503) {
      window.location.href = "/maintenance";
    } else {
      throw new Error("Could not fetch data");
    }
  } else {
    return response.json();
  }
}

export async function getSignedForComponent({
  uuid,
  expires,
  signature,
  page,
  component,
}: {
  uuid: string;
  expires: string | null;
  signature: string;
  page: string;
  component: string;
}) {
  const urlParams = new URLSearchParams({
    signature,
  });

  if (expires) {
    urlParams.append("expires", expires);
  }

  const domain = process.env.REACT_APP_AUTH_DOMAIN;
  const path = `spa/NI/${page}/${component}/${uuid}`;
  const url = `${domain}/${path}?${urlParams.toString()}`;

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "x-swipesense-platform": "spa",
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.assign(
        process.env.REACT_APP_AUTH_DOMAIN +
          "?redirectAddress=" +
          window.location.href +
          "&_storeSourceAsIntendedUrl=true"
      );
      throw new Response("Expired", { status: 401 });
    } else if (response.status === 403) {
      throw new Response("Forbidden", { status: 403 });
    } else if (response.status === 503) {
      window.location.href = "/maintenance";
    } else {
      throw new Error("Could not fetch data");
    }
  } else {
    return response.json();
  }
}

interface PostParams {
  body?: any;
  url: string;
  additionalHeaders?: { [key: string]: string };
}

export async function post(params: PostParams) {
  const { body, url, additionalHeaders } = params;
  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "x-swipesense-platform": "spa",
    ...additionalHeaders,
  };

  const response = await fetch(url, {
    body: JSON.stringify(body),
    method: "POST",
    credentials: "include",
    headers,
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.assign(
        process.env.REACT_APP_AUTH_DOMAIN +
          "?redirectAddress=https://" +
          process.env.REACT_APP_ANALYTICS_DOMAIN +
          "&_storeSourceAsIntendedUrl=true"
      );
      throw new Response("Expired", { status: 401 });
    } else if (response.status === 403) {
      throw new Response("Forbidden", { status: 403 });
    } else if (response.status === 503) {
      window.location.href = "/maintenance";
    } else {
      throw new Error("Could not fetch data");
    }
  } else {
    if (response.status === 204) {
      return response;
    }

    return response.json();
  }
}

interface PatchParams {
  body?: any;
  url: string;
  additionalHeaders?: { [key: string]: string };
}

export async function patch(params: PatchParams) {
  const { body, url, additionalHeaders } = params;
  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "x-swipesense-platform": "spa",
    ...additionalHeaders,
  };

  const response = await fetch(url, {
    body: JSON.stringify(body),
    method: "PATCH",
    credentials: "include",
    headers,
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.assign(
        process.env.REACT_APP_AUTH_DOMAIN +
          "?redirectAddress=https://" +
          process.env.REACT_APP_ANALYTICS_DOMAIN +
          "&_storeSourceAsIntendedUrl=true"
      );
      throw new Response("Expired", { status: 401 });
    } else if (response.status === 403) {
      throw new Response("Forbidden", { status: 403 });
    } else if (response.status === 503) {
      window.location.href = "/maintenance";
    } else {
      throw new Error("Could not fetch data");
    }
  } else {
    if (response.status === 204) {
      return response;
    }

    return response.json();
  }
}

export async function deleteMethod(params) {
  const { body, url, additionalHeaders } = params;
  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "x-swipesense-platform": "spa",
    ...additionalHeaders,
  };

  const response = await fetch(url, {
    body: JSON.stringify(body),
    method: "DELETE",
    credentials: "include",
    headers,
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.assign(
        process.env.REACT_APP_AUTH_DOMAIN +
          "?redirectAddress=https://" +
          process.env.REACT_APP_ANALYTICS_DOMAIN +
          "&_storeSourceAsIntendedUrl=true"
      );
      throw new Response("Expired", { status: 401 });
    } else if (response.status === 403) {
      throw new Response("Forbidden", { status: 403 });
    } else if (response.status === 503) {
      window.location.href = "/maintenance";
    } else {
      throw new Error("Could not fetch data");
    }
  } else {
    if (response.status === 204) {
      return response;
    }

    return response.json();
  }
}

export async function fetchUserInfo() {
  return await get(userAccessEndpoint);
}
