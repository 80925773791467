import Cards from "../UI/Cards/Cards";
import { tooltipContent } from "../../content";
import Tooltips from "../UI/Tooltip/Tooltip";
import { useEffect, useState } from "react";
import ComplianceBucketButtons from "../ComplianceBucketButtons/ComplianceBucketButtons";
import CircularHeatmap from "../CircularHeatmap/CircularHeatmap";
import { formatPercentage } from "../../utils";
import MissingData from "../MissingData/MissingData";
import { missingDataAction } from "../../constants";
import useThresholdButtons from "../Filters/hooks/useThresholdButtons";

interface HeatmapProps {
  data: {
    data: { x: number; y: number; name?: string; percentage: number }[];
  }[];
  viewingBy: string;
  xLabels: string[];
  yLabels: Object[];
  id?: string;
  onClick?: (facilityId: string) => void;
  onFacilitiesView: boolean;
  onCardClick?: (facilityId: string) => void;
  goalMet: { facilityId: string | null; goalMet: boolean }[];
}

function Heatmap({ xLabels, yLabels, ...props }: HeatmapProps) {
  const { selectedButton, setSelectedButton, thresholdOne, thresholdTwo } =
    useThresholdButtons(props.data);

  const [heatmapData, setHeatmapData] = useState(props.data);
  const [buttonToggled, setButtonToggled] = useState(false);

  const complianceButtonToggled = () => {
    setButtonToggled(!buttonToggled);
  };

  const replaceYValuesBetweenThresholds = (
    dataArray: {
      data: {
        x: number;
        y: number;
        goal_met?: boolean;
        facility_id?: number | null;
      }[];
    }[],
    lowerThreshold: number,
    upperThreshold: number,
    buttonToggled: boolean
  ) => {
    const updatedDataArray = dataArray?.map((facility) => ({
      ...facility,
      data: facility.data.map((entry) => {
        const percentageValue = formatPercentage(entry.y);
        const isWithinThreshold =
          percentageValue >= lowerThreshold &&
          percentageValue <= upperThreshold;

        const isValidWithoutToggle = !buttonToggled && isWithinThreshold;

        const isValidWithToggle =
          buttonToggled &&
          isWithinThreshold &&
          entry.goal_met !== false &&
          entry.facility_id === null;

        return {
          ...entry,
          percentage:
            isValidWithoutToggle || isValidWithToggle ? percentageValue : -1,
        };
      }),
    }));

    return updatedDataArray;
  };

  const allDataLengthsZero = heatmapData?.every(
    (data) => data.data.length === 0
  );

  useEffect(() => {
    setHeatmapData(
      replaceYValuesBetweenThresholds(
        props.data,
        thresholdOne,
        thresholdTwo,
        buttonToggled
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedButton, props.data, thresholdOne, thresholdTwo, buttonToggled]);

  return (
    <div className="heatmap" data-testid="svg-heatmap" id="heatmap">
      <Cards
        headerLeft={<h2>Hand Hygiene Performance by {props.viewingBy}</h2>}
        headerRight={
          <Tooltips
            id={"heatmap-tooltip"}
            content={tooltipContent.executiveDashboard["en-US"]}
          ></Tooltips>
        }
      >
        {!props.data || allDataLengthsZero ? (
          <>
            <MissingData
              message={"No data for Heatmap"}
              action={missingDataAction}
            />
          </>
        ) : (
          <>
            <ComplianceBucketButtons
              isHeatmap={true}
              buttonToggled={complianceButtonToggled}
              selectedButton={selectedButton}
              onSelectButton={setSelectedButton}
            />
            <CircularHeatmap
              id={props.id}
              xLabels={xLabels}
              yLabels={yLabels}
              dataSets={heatmapData}
              onButtonClick={props.onClick}
              onFacilitiesView={props.onFacilitiesView}
            />
          </>
        )}
      </Cards>
    </div>
  );
}

export default Heatmap;
