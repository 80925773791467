import { useEffect, useState } from "react";
import useExecutiveReportViewModel from "../../viewmodel/Executive/useExecutiveReportViewModel";

import useSharedFunctions from "../sharedFunctions/useSharedFunctions";
import { useSearchParams } from "react-router-dom";

const useExecutiveReportViewController = () => {
  const { transformedExecutiveReportData } = useExecutiveReportViewModel();
  const {
    onFilterChange,
    filters,
    applyFilters,
    toggleButton,
    disableExcelDoc,
  } = useSharedFunctions(transformedExecutiveReportData);

  const [searchParams, setSearchParams] = useSearchParams({});
  const [viewingBy, setViewingBy] = useState("Facility");
  const [currentFacility, setCurrentFacility] = useState("");
  const facilitiesIds = searchParams.getAll("FacilitiesIds");

  const onFacilitiesView =
    (facilitiesIds.length === 1 && facilitiesIds[0] === "null") ||
    facilitiesIds.length === 0;

  const returnToAllFacilities = () => {
    setViewingBy(`Facility`);
    setCurrentFacility("");
    setSearchParams({
      FacilitiesIds: ["null"],
    });
  };

  const handleCardClick = (facilityId: string) => {
    const facilityIdOfCell = facilityId;

    setViewingBy(`Unit`);
    if (!!facilityIdOfCell) {
      setCurrentFacility(facilityId);
      setSearchParams({
        FacilitiesIds: [facilityId],
      });
    }
  };

  useEffect(() => {
    if (onFacilitiesView) {
      setViewingBy(`Facility`);
    } else {
      setViewingBy(`Unit`);
    }
  }, [searchParams, onFacilitiesView]);

  return {
    transformedExecutiveReportData,
    filters,
    onFilterChange,
    applyFilters,
    returnToAllFacilities,
    viewingBy,
    currentFacility,
    onFacilitiesView,
    toggleButton,
    disableExcelDoc,
    handleCardClick,
  };
};

export default useExecutiveReportViewController;
