import {
  parseOptionsHeaders,
  parseOptions,
  isRawOptionsWithHeaders,
  isGroupedOptions,
} from "../Components/Filters/hooks/helpers";
import {
  RawOptionsObject,
  RawOptionsWithHeaders,
} from "../Components/Filters/types/types";
import { Option, GroupedOptions } from "../types/types";

type FilterKey = {
  options: RawOptionsObject | RawOptionsWithHeaders;
  value: null | string | boolean | number[];
};

export function findSelection(filterKey: FilterKey): Option[] {
  if (filterKey.value === null || filterKey.value === undefined) return [];
  const flattenOptions = (options: GroupedOptions[]) => {
    let flatOptions: Option[] = [];

    options.forEach((group) => {
      flatOptions = [...flatOptions, ...group.options];
    });

    return flatOptions;
  };

  const buildSelection = (
    options: Option[] | GroupedOptions[],
    value: string | boolean | number[]
  ) => {
    let flatOptions: Option[];
    if (isGroupedOptions(options)) {
      flatOptions = flattenOptions(options);
    } else {
      flatOptions = options;
    }

    //if value is string, return the option key that matches that string
    if (typeof value === "string") {
      return flatOptions.filter((opt) => opt.value === value);
    }

    //if value is boolean, return 1 for true, 0 for false
    if (typeof value === "boolean") {
      return filterKey.value ? [flatOptions["1"]] : [flatOptions["0"]];
    }

    return flatOptions.filter((opt) => {
      return value.includes(parseInt(opt.value));
    });
  };

  let selection: Option[] = [];

  let responseOptions: Option[] | GroupedOptions[] = [];
  if (isRawOptionsWithHeaders(filterKey.options)) {
    responseOptions = parseOptionsHeaders(filterKey.options);
  } else {
    responseOptions = parseOptions(filterKey.options);
  }

  selection = buildSelection(responseOptions, filterKey.value);

  return selection;
}
