export const unitReportEndpoint = "spa/HH/report/unit";
export const groupReportEndpoint = "spa/HH/report/group";
export const executiveDashboardReportEndpoint = "spa/HH/dashboard/executive";
export const userReportEndpoint = "spa/HH/report/staff";
export const individualUserReportEndpoint = "spa/HH/report/staff/individual";
export const myReportEndpoint = "spa/HH/report/me";
export const rtibEndpoint = "spa/HH/report/blueprint/realTime";
export const otibEndpoint = "spa/HH/report/blueprint/overTime";
export const assetsEndpoint = "spa/rest-api/assets";
export const roundingEndpoint = "spa/rest-api/places";
export const roomsHistoryEndpoint = "spa/rest-api/rooms-history";
export const roomsOverviewParamsEndpoint = "spa/rest-api/rooms-overview";
export const roomsOverviewSummaryEndpoint = "spa/NI/rooms-overview/summary";
export const roomsOverviewTableEndpoint = "spa/NI/rooms-overview/rooms";
export const staffOverviewParamsEndpoint = "spa/rest-api/staff-overview";
export const staffOverviewSummaryEndpoint = "spa/NI/staff-overview/summary";
export const staffOverviewTableEndpoint = "spa/NI/staff-overview/table";
export const caseOptionsEndpoint = "spa/rest-api/case/options";
export const caseEndpoint = "spa/rest-api/cases";
export const newCaseEndpoint = "spa/rest-api/case";
export const positiveStaffCaseEndpoint = "spa/rest-api/positive-staff-case";
export const positiveRoomCaseEndpoint = "spa/rest-api/positive-room-case";
export const userAccessEndpoint = "ajax/userAccess/current";
export const missingDataMessage = "No data found.";
export const missingDataMessageAT = "No data provided.";
export const missingDataAction = "Try a different selection or date range.";
export const missingChangeDataMessage = "No change data";
export const maxDayFilter = 31;
export const maxWeekFilter = 53;

export const defaultDateRange = {
  startDate: new Date(new Date().setDate(new Date().getDate() - 7 * 10)),
  endDate: new Date(),
};

export const oneWeekDateRange = {
  startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
  endDate: new Date(),
};

export const modulesData = [
  {
    moduleId: "HH",
    url: "/HH/",
    iconUrl: "/hh-logo.svg",
    testid: "hh-logo",
    isModuleToggle: true,
  },
  {
    moduleId: "AT",
    url: "/AT/",
    iconUrl: "/at_app.svg",
    testid: "at-logo",
    isModuleToggle: true,
  },
  {
    moduleId: "CT",
    url: "/CT/",
    iconUrl: "/ct-logo.svg",
    testid: "ct-logo",
    isModuleToggle: true,
  },
  {
    moduleId: "NI",
    url: "/NI/",
    iconUrl: "/ni-logo.svg",
    testid: "ni-logo",
    isModuleToggle: true,
  },
];

export const performanceRanges = [
  { x: 5, label: "0-10%" },
  { x: 15, label: "10-20%" },
  { x: 25, label: "20-30%" },
  { x: 35, label: "30-40%" },
  { x: 45, label: "40-50%" },
  { x: 55, label: "50-60%" },
  { x: 65, label: "60-70%" },
  { x: 75, label: "70-80%" },
  { x: 85, label: "80-90%" },
  { x: 95, label: "90-100%" },
];

export const complianceThresholds = {
  0: {
    id: 0,
    min: 0.1,
    max: 30,
    className: "red-compliance",
    label: "0%-30%",
    thresholdOne: 0,
    thresholdTwo: 29.99,
  },
  1: {
    id: 1,
    min: 30,
    max: 60,
    className: "yellow-compliance",
    label: "30%-60%",
    thresholdOne: 30,
    thresholdTwo: 59.99,
  },
  2: {
    id: 2,
    min: 60,
    max: 80,
    className: "green-compliance",
    label: "60%-80%",
    thresholdOne: 60,
    thresholdTwo: 79.99,
  },
  3: {
    id: 3,
    min: 80,
    max: 100.1,
    className: "blue-compliance",
    label: "80%-100%",
    thresholdOne: 80,
    thresholdTwo: 100,
  },
};
