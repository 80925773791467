import { get } from "../../api/laravelApi";
import { rtibEndpoint } from "../../constants";
import { getApiUrlFromGetRequest } from "../../utils/";
import { getSearchParams } from "../loaderHelper";

export const RtibLoader = async (request) => {
  const url = new URL(request.url);
  const searchParams = new URLSearchParams(url.search);

  const allParams = getSearchParams(searchParams);

  const getAllFiltersJson = await get(rtibEndpoint);
  const allFiltersJson = getAllFiltersJson._params;
  const transformedFiltersJson = Object.values(allFiltersJson).map((value) => {
    const filterValue = value as {
      key: string;
      searchParamName: string;
      type: string;
    };
    return {
      key: filterValue.key,
      label: filterValue.searchParamName,
      type: filterValue.type,
    };
  });

  const rtibData = await get(
    rtibEndpoint,
    allParams,
    transformedFiltersJson
  ).then((rtibData) => {
    const params = rtibData._params;

    const rtibDataApiUrl = rtibData.nodeMaps;

    const error = rtibData._error;

    const uuid = rtibData?._report?.uuid;

    const apiUrlWithoutDomain = getApiUrlFromGetRequest(rtibDataApiUrl, error);

    const fetchPromises = apiUrlWithoutDomain.map((url) =>
      get(url).then((result) => {
        const data = result.data;

        return data;
      })
    );

    return Promise.all(fetchPromises).then((results) => {
      const validDataResults = results.filter((data) => data !== null);

      return { validDataResults, params, error, uuid };
    });
  });

  return {
    rtibData,
  };
};
