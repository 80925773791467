import { StaffReportDTO } from "../../model/types";
import { ApiDataError, StaffReport } from "../../types/types";
import { isEmptyArray, parseDateToMidnight } from "../../utils";
import { transformFilters } from "../utils";

export function transformStaffReport(
  responseJson: StaffReportDTO | null
): StaffReport {
  let startDate, endDate;
  const dateRange = responseJson?._params?.date_range?.value;

  if (!dateRange) {
    startDate = responseJson?.reportSubtitle?.startDate;
    endDate = responseJson?.reportSubtitle?.endDate;
  } else {
    startDate = dateRange[1];
    endDate = dateRange[2];
  }

  const dataSeries = responseJson?.data;
  const response: StaffReport = {
    tiles: {
      total:
        responseJson?.totalOpportunities === 0
          ? null
          : (responseJson?.totalOpportunities ?? null),
      performant:
        responseJson?.compliantOpportunities === 0
          ? null
          : (responseJson?.compliantOpportunities ?? null),
      performance: null,
      percentChangedFormatted: responseJson?.performanceDelta ?? "0%",
    },
    startDate: startDate ? new Date(parseDateToMidnight(startDate)) : null,
    endDate: endDate ? new Date(parseDateToMidnight(endDate)) : null,
    averageCompliance: [],
    filters: [],
    error: null,
    excelUrl: "",
    userInfo: {},
    uuid: "",
  };

  if (!responseJson) {
    return response;
  }

  if (response.tiles.total && response.tiles.performant) {
    response.tiles.performance =
      response.tiles.performant / response.tiles.total;
  }
  response.userInfo = responseJson?._params?.user_ids?.options;

  response.filters = transformFilters(responseJson._params);

  const isApiDataError = (
    error: any[] | ApiDataError
  ): error is ApiDataError => {
    return !isEmptyArray(error);
  };

  // the api will return an empty array for _error if there are no errors
  // but will return an error object if there is an error
  if (isApiDataError(responseJson._error)) {
    response.error = responseJson._error;
  } else {
    response.error = null;
  }

  const parseIndividuals = dataSeries?.map((data) => {
    return {
      provider_id: data.provider_id,
      name: data.name,
      percentage: Number(data?.performance),
      opportunities: Number(data.opportunities),
      lastDetected: data.lastDetected ? data.lastDetected : "Missing Data",
    };
  });

  response.excelUrl = responseJson?._export?.url;

  response.averageCompliance = parseIndividuals ?? [];

  response.uuid = responseJson?._report?.uuid;

  return response;
}
