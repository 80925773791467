import { useReducer } from "react";
import { Filter, Option, GroupedOptions } from "../../../../src/types/types";
import {
  getAllNewSelectedOptions,
  getDependencyValuesForPeerFilters,
  isGroupedOptions,
  isGroupedSelection,
  isRawOptionsWithHeaders,
  parseOptions,
  parseOptionsHeaders,
} from "./helpers";
import {
  DependencyValues,
  RawOptionsObject,
  RawOptionsWithHeaders,
} from "../types/types";

type Action =
  | {
      type: "SET_SELECTED";
      payload: { filterName: string; selected: Option[] };
    }
  | {
      type: "SET_OPTIONS";
      payload: { filterName: string; options: Option[] | GroupedOptions[] };
    }
  | { type: "SET_FILTER_DIRTY"; payload: { filterName: string } }
  | {
      type: "SET_PEER_FILTERS";
      payload: { filterName: string; peerFilters: string[] };
    }
  | { type: "SET_FILTER_CLEAN"; payload: { filterName: string } }
  | {
      type: "VALIDATE_SELECTED";
      payload: { filterName: string; options: Option[] | GroupedOptions[] };
    };

const filterReducer = (state: Filter[], action: Action): Filter[] => {
  const filterIndex = state.findIndex(
    (filter) => filter.name === action.payload.filterName
  );
  const newState = [...state];

  switch (action.type) {
    case "SET_SELECTED":
      newState[filterIndex].selection = action.payload.selected;
      return newState;
    case "SET_OPTIONS":
      newState[filterIndex].options = action.payload.options;

      return newState;
    case "VALIDATE_SELECTED":
      newState[filterIndex].selection = newState[filterIndex].selection.filter(
        (selectedOption) => {
          if (!isGroupedOptions(action.payload.options)) {
            return (
              action.payload.options.find(
                (option) => option.value === selectedOption.value
              ) !== undefined
            );
          } else {
            return (
              action.payload.options
                .map((group) => {
                  return group.options;
                })
                .flat()
                .find((option) => option.value === selectedOption.value) !==
              undefined
            );
          }
        }
      );

      // if the new options have a group label and our current selection does not
      // then we need to add that group label to each option so that we can display it to the user
      if (
        (isGroupedOptions(action.payload.options) &&
          !isGroupedSelection(newState[filterIndex].selection)) ||
        (!isGroupedOptions(action.payload.options) &&
          isGroupedSelection(newState[filterIndex].selection))
      ) {
        newState[filterIndex].selection = getAllNewSelectedOptions(
          newState[filterIndex].selection,
          action.payload.options
        );
      }

      return newState;
    case "SET_FILTER_DIRTY":
      newState[filterIndex].isDirty = true;
      return newState;
    case "SET_FILTER_CLEAN":
      newState[filterIndex].isDirty = false;
      return newState;
    case "SET_PEER_FILTERS":
      newState[filterIndex].peerFilters = action.payload.peerFilters;
      return newState;
    default:
      return state;
  }
};

export const useFilterState = (
  fetchOptions: (
    reloadingUrl: string,
    dependencyValues: DependencyValues
  ) => Promise<RawOptionsObject | RawOptionsWithHeaders>,
  initialState: {
    initialFilters: Filter[];
  } = {
    initialFilters: [],
  }
) => {
  const initialFilters = initialState.initialFilters;

  const [filters, dispatch] = useReducer(filterReducer, initialFilters);

  const setAllFiltersDirty = () => {
    filters.forEach((filter) => {
      dispatch({
        type: "SET_FILTER_DIRTY",
        payload: { filterName: filter.name },
      });
    });
    updateDirtyFilters();
  };

  const setSelected = async (filterName: string, selected: Option[]) => {
    selected = selected.filter((option) => option.value !== "null");
    dispatch({ type: "SET_SELECTED", payload: { filterName, selected } });

    await setDependentFiltersDirty(filters);

    await updateDirtyFilters();
  };

  const setPeerFilters = async (filterName: string, peerFilters: string[]) => {
    dispatch({
      type: "SET_PEER_FILTERS",
      payload: { filterName, peerFilters },
    });
  };

  const optionsCache = new Map();

  let isUpdateRunning = false;

  const updateDirtyFilters = async () => {
    if (isUpdateRunning) return;
    isUpdateRunning = true;

    const updatedFilters = new Set();

    for (const filterState of filters) {
      if (filterState.isDirty && !updatedFilters.has(filterState.name)) {
        let shouldUpdate = true;

        for (const peerFilter of filterState.peerFilters) {
          const peerFilterState = filters.find(
            (filter) => filter.name === peerFilter
          );

          if (peerFilterState?.isDirty) {
            shouldUpdate = false;
            break;
          }
        }

        if (shouldUpdate) {
          const dependencyValues = getDependencyValuesForPeerFilters(
            filterState.peerFilters,
            filters
          );

          const cacheKey = JSON.stringify({
            filterName: filterState.name,
            dependencyValues,
          });

          let allOptions: Option[] | GroupedOptions[];
          if (optionsCache.has(cacheKey)) {
            allOptions = optionsCache.get(cacheKey); // Use cached options
          } else {
            let options = await fetchOptions(
              filterState.reloadingUrl,
              dependencyValues
            );

            allOptions = isRawOptionsWithHeaders(options)
              ? parseOptionsHeaders(options)
              : parseOptions(options);

            optionsCache.set(cacheKey, allOptions);
          }

          dispatch({
            type: "SET_OPTIONS",
            payload: {
              filterName: filterState.name,
              options: allOptions,
            },
          });

          dispatch({
            type: "SET_FILTER_CLEAN",
            payload: { filterName: filterState.name },
          });

          dispatch({
            type: "VALIDATE_SELECTED",
            payload: { filterName: filterState.name, options: allOptions },
          });

          updatedFilters.add(filterState.name);
        }
      }
    }

    isUpdateRunning = false;
  };

  const setDependentFiltersDirty = async (filterName: Filter[]) => {
    const convertParamFilterNameToState = (filterName: Filter[]) => {
      const mapping = filterName.find((item) => item.name);
      if (mapping) {
        return mapping.filterKey;
      } else {
        console.error("Filter name not found for ", filterName);
        return "";
      }
    };

    filters.forEach((filter) => {
      const filters = convertParamFilterNameToState(filterName);

      if (filter.peerFilters.includes(filters ?? "")) {
        dispatch({
          type: "SET_FILTER_DIRTY",
          payload: { filterName: filter.name },
        });
      }
    });
  };

  return {
    filters,
    setAllFiltersDirty,
    setSelected,
    setPeerFilters,
  };
};
