import { defer } from "react-router-dom";
import { get } from "../../api/laravelApi";
import {
  staffOverviewParamsEndpoint,
  staffOverviewSummaryEndpoint,
  staffOverviewTableEndpoint,
} from "../../constants";
import { getSearchParamsDefer } from "../loaderHelper";

export const StaffOverviewLoader = async (request: { url: string | URL }) => {
  const url = new URL(request.url);
  const searchParams = new URLSearchParams(url.search);

  const allParams = getSearchParamsDefer(searchParams);

  const getAllFiltersJson = await get(staffOverviewParamsEndpoint);
  const testGetParams = getAllFiltersJson.params;
  const transformedFiltersJson = Object.values(testGetParams).map((value) => {
    const filterValue = value as {
      key: string;
      searchParamName: string;
      type: string;
    };
    return {
      key: filterValue.key,
      label: filterValue.searchParamName,
      type: filterValue.type,
    };
  });

  return get(staffOverviewParamsEndpoint, allParams, transformedFiltersJson)
    .then((resp) => {
      return [allParams, resp];
    })
    .then(([_params, resp]) => {
      const summary = get(
        staffOverviewSummaryEndpoint,
        allParams,
        transformedFiltersJson
      );
      const staffTable = get(
        staffOverviewTableEndpoint,
        allParams,
        transformedFiltersJson
      );

      return defer({
        summary,
        staffTable,
        filters: resp,
      });
    });
};
