import { useEffect } from "react";
import DatePicker from "../../Components/DatePicker/DatePicker";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import Filters from "../../Components/Filters/Filters";
import HardwareButton from "../../Components/HardwareButton/HardwareButton";
import Table from "../../Components/Table/Table";

import Cards from "../../Components/UI/Cards/Cards";
import { useUUIDWithLocation } from "../../Hooks/useUuid";
import { oneWeekDateRange } from "../../constants";
import useRoomsHistoryViewController from "../../viewcontroller/RoomsHistory/useRoomsHistoryViewController";
import DatePickerDropdown from "../../Components/DatePickerDropdown/DatePickerDropdown";

function RoomsHistoryView() {
  const {
    roomsHistoryData,
    handleDateChange,
    filters,
    onFilterChange,
    applyFilters,
    toggleButton,
    tableColumns,
    tableData,
    tableOptions,
    disableExcelDoc,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  } = useRoomsHistoryViewController();

  const { updateUUID } = useUUIDWithLocation();

  useEffect(() => {
    updateUUID(roomsHistoryData?.uuid);
  }, [roomsHistoryData?.uuid, updateUUID]);

  return (
    <>
      <div className="page-header">
        <h1>Rooms History</h1>
        <div className="buttons-container">
          <ExcelDownload
            link={roomsHistoryData.excelUrl}
            disabled={disableExcelDoc}
          />
          <HardwareButton
            link={
              process.env.REACT_APP_AUTH_DOMAIN + "/dashboard/vitalSigns/node"
            }
          />
        </div>
      </div>
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <div>
              <DatePickerDropdown
                startDateLabel={
                  roomsHistoryData.startDate ?? oneWeekDateRange.startDate
                }
                endDateLabel={
                  roomsHistoryData.endDate ?? oneWeekDateRange.endDate
                }
                singleWeek
                onChange={handleDatePickerChange}
              />
              {isDatePickerVisible && (
                <DatePicker
                  handleDatePickerChange={() => handleDatePickerChange}
                  visible={isDatePickerVisible}
                  maxNumDays={7}
                  maxNumWeeks={1}
                  startDate={
                    roomsHistoryData.startDate ?? oneWeekDateRange.startDate
                  }
                  endDate={roomsHistoryData.endDate ?? oneWeekDateRange.endDate}
                  onChange={handleDateChange}
                  handleClick={handleDatePickerButtonClick}
                />
              )}
            </div>
            <Filters
              filters={filters}
              onChange={onFilterChange}
              toggleButton={toggleButton}
              applyFilters={applyFilters}
              singleSelect
            />
          </div>
        </Cards>
      </div>
      <div className="rooms-history-table">
        <Table
          columns={tableColumns}
          optionOverrides={tableOptions}
          data={tableData}
          title={"Rooms History"}
        ></Table>
      </div>
    </>
  );
}

export default RoomsHistoryView;
