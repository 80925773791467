import { get } from "../../api/laravelApi";
import { individualUserReportEndpoint } from "../../constants";
import { getSearchParams } from "../loaderHelper";

export const IndividualStaffLoader = async ({ request }) => {
  const url = new URL(request.url);
  const searchParams = new URLSearchParams(url.search);

  const allParams = getSearchParams(searchParams);

  const getAllFiltersJson = await get(individualUserReportEndpoint);
  const allFiltersJson = getAllFiltersJson._params;
  const transformedFiltersJson = Object.values(allFiltersJson).map((value) => {
    const filterValue = value as {
      key: string;
      searchParamName: string;
      type: string;
    };
    return {
      key: filterValue.key,
      label: filterValue.searchParamName,
      type: filterValue.type,
    };
  });

  const individualStaffReportData = await get(
    individualUserReportEndpoint,
    allParams,
    transformedFiltersJson
  );

  return individualStaffReportData;
};
