import { get } from "../../api/laravelApi";
import { userReportEndpoint } from "../../constants";
import { getSearchParams } from "../loaderHelper";

export const StaffLoader = async (request) => {
  const url = new URL(request.url);
  const searchParams = new URLSearchParams(url.search);

  const allParams = getSearchParams(searchParams);

  const getAllFiltersJson = await get(userReportEndpoint);
  const allFiltersJson = getAllFiltersJson._params;
  const transformedFiltersJson = Object.values(allFiltersJson).map((value) => {
    const filterValue = value as {
      key: string;
      searchParamName: string;
      type: string;
    };
    return {
      key: filterValue.key,
      label: filterValue.searchParamName,
      type: filterValue.type,
    };
  });

  const staffReportData = await get(
    userReportEndpoint,
    allParams,
    transformedFiltersJson
  );

  return staffReportData;
};
